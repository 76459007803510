// selected colors by user
$selected-primary-color: var(--selected-primary-color);

.primaryBtn {
  display: flex;
  padding: 10px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid $selected-primary-color;
  background-color: $selected-primary-color  !important;
  width: 100%;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: var(--app-primary-font-size);
  font-weight: var(--app-bold-font-weight);
  color: var(--app-base-white-color);
  stroke:  var(--app-base-white-color);

  &:hover {
    background-color: $selected-primary-color !important;
    border: 1px solid $selected-primary-color;
    box-shadow: none !important;
  }

  &:focus {
    background-color: $selected-primary-color !important;
    border: 1px solid $selected-primary-color;
    box-shadow: none !important;
  }

  &:disabled {
    background-color: $selected-primary-color !important;
    border: 1px solid $selected-primary-color;
    box-shadow: none !important;
  }
}

.primarySmallBtn{
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--app-primary-600-color);
  background: var(--app-primary-600-color);
  width: 100%;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: var(--app-primary-font-size);
  font-weight: var(--app-bold-font-weight);
  color: var(--app-base-white-color);
  stroke:  var(--app-base-white-color);

  &:hover {
    background-color: var(--app-primary-600-color) !important;
    box-shadow: none !important;
  }

  &:focus {
    background-color: var(--app-primary-600-color) !important;
    box-shadow: none !important;
  }

  &:disabled {
    background-color: var(--app-primary-600-color) !important;
    box-shadow: none !important;
  }
}

.borderSecondaryBtn{
  display: flex;
  padding: 10px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--app-primary-500-color);
  background: none;
  width: 100%;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: var(--app-primary-font-size);
  font-weight: var(--app-bold-font-weight);
  color: var(--app-primary-700-color);
  stroke: var(--app-primary-700-color);

  &:hover {
    background-color: var(--app-primary-700-color) !important;
    box-shadow: none !important;
    color: var(--app-base-white-color);
    stroke: var(--app-base-white-color);
  }

  &:focus {
    background-color: var(--app-primary-700-color) !important;
    box-shadow: none !important;
    color: var(--app-base-white-color);
    stroke: var(--app-base-white-color);
  }

  &:disabled {
    background-color: var(--app-primary-700-color) !important;
    box-shadow: none !important;
    color: var(--app-base-white-color);
    stroke: var(--app-base-white-color);
  }
}

.secondaryBtn{
  display: flex;
  padding: 10px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--app-primary-50-color);
  background: var(--app-primary-50-color);
  width: 100%;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: var(--app-primary-font-size);
  font-weight: var(--app-bold-font-weight);
  color: var(--app-primary-700-color);
  stroke: var(--app-primary-700-color);

  &:hover {
    background-color: var(--app-primary-700-color) !important;
    box-shadow: none !important;
    color: var(--app-base-white-color);
    stroke: var(--app-base-white-color);
  }

  &:focus {
    background-color: var(--app-primary-700-color) !important;
    box-shadow: none !important;
    color: var(--app-base-white-color);
    stroke: var(--app-base-white-color);
  }

  &:disabled {
    background-color: var(--app-primary-700-color) !important;
    box-shadow: none !important;
    color: var(--app-base-white-color);
    stroke: var(--app-base-white-color);
  }
}

.secondarySmallBtn{
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--app-primary-50-color);
  background: var(--app-primary-50-color);
  width: 100%;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: var(--app-primary-font-size);
  font-weight: var(--app-bold-font-weight);
  color: var(--app-primary-700-color);
  stroke: var(--app-primary-700-color);

  &:hover {
    background-color: var(--app-primary-700-color) !important;
    box-shadow: none !important;
  }

  &:focus {
    background-color: var(--app-primary-700-color) !important;
    box-shadow: none !important;
  }

  &:disabled {
    background-color: var(--app-primary-700-color) !important;
    box-shadow: none !important;
  }
}



.defaultBtn {
  width: 100%;
  display: flex;
  padding: 10px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid $selected-primary-color !important;
  background: var(--app-base-white-color) !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  color: $selected-primary-color !important;
  stroke: var(--app-gray-700-color) !important;
  font-size: var(--app-primary-font-size);
  font-weight: var(--app-bold-font-weight);

  &:hover {
    background-color: var(--app-base-white-color) !important;
    box-shadow: none !important;
  }

  &:focus {
    background-color: var(--app-base-white-color) !important;
    box-shadow: none !important;
  }

  &:disabled {
    background-color: var(--app-base-white-color) !important;
    box-shadow: none !important;
  }
}

.defaultBtn p{
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-primary-font-size);
}

.defaultSmallBtn {
  width: 100%;
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--app-gray-300-color) !important;
  background: var(--app-base-white-color) !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  color: var(--app-gray-700-color) !important;
  stroke: var(--app-gray-700-color) !important;
}

.successBtn{
  display: flex;
  padding: 10px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--app-success-50-color);
  background: var(--app-success-50-color);
  width: 100%;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: var(--app-primary-font-size) !important;
  font-weight: var(--app-bold-font-weight) !important;
  color: var(--app-success-700-color) !important;
  stroke:  var(--app-success-700-color) !important;

  &:hover {
    background-color: var(--app-success-50-color) !important;
    box-shadow: none !important;
  }

  &:focus {
    background-color: var(--app-success-50-color) !important;
    box-shadow: none !important;
  }

  &:disabled {
    background-color: var(--app-success-50-color) !important;
    box-shadow: none !important;
    border: 1px solid var(--app-success-50-color);
  }
}

.successSmallBtn{
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--app-success-50-color);
  background: var(--app-success-50-color);
  width: 100%;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: var(--app-primary-font-size) !important;
  font-weight: var(--app-bold-font-weight) !important;
  color: var(--app-success-700-color) !important;
  stroke:  var(--app-success-700-color) !important;

  &:hover {
    background-color: var(--app-success-50-color) !important;
    box-shadow: none !important;
  }

  &:focus {
    background-color: var(--app-success-50-color) !important;
    box-shadow: none !important;
  }

  &:disabled {
    background-color: var(--app-success-50-color) !important;
    border: 1px solid var(--app-success-50-color);
    box-shadow: none !important;
  }
}

.warningBtn{
  display: flex;
  padding: 10px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--app-warning-50-color);
  background: var(--app-warning-50-color);
  width: 100%;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: var(--app-primary-font-size) !important;
  font-weight: var(--app-bold-font-weight) !important;
  color: var(--app-warning-700-color) !important;
  stroke: var(--app-warning-700-color) !important;
}

.dangerBtn{
  display: flex;
  padding: 10px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--app-error-50-color);
  background: var(--app-error-50-color);
  width: 100%;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  font-size: var(--app-primary-font-size) !important;
  font-weight: var(--app-bold-font-weight) !important;
  color: var(--app-error-700-color) !important;
  stroke: var(--app-error-700-color) !important;

  &:hover {
    background-color:  var(--app-error-50-color) !important;
    border: 1px solid var(--app-error-50-color);
    box-shadow: none !important;
  }

  &:focus {
    background-color: var(--app-error-50-color) !important;
    border: 1px solid var(--app-error-50-color);
    box-shadow: none !important;
  }

  &:disabled {
    background-color: var(--app-error-50-color) !important;
    border: 1px solid var(--app-error-50-color);
    box-shadow: none !important;
  }
}
@media only screen and (max-width: 600px) {
  .primaryBtn {
    font-size: var(--app-extra-small-font-size);
  }

  .primarySmallBtn{
    padding: 10px 14px;
    font-size: var(--app-extra-small-font-size);
  }
  
  .secondaryBtn{
    font-size: var(--app-extra-small-font-size);
  }
  
  .secondarySmallBtn{
    padding: 10px 14px;
    font-size: var(--app-extra-small-font-size);
  }
  
  .defaultBtn {
    font-size: var(--app-extra-small-font-size);
  }
  
  .defaultBtn p{
    font-size: var(--app-extra-small-font-size);
  }
  
  .defaultSmallBtn {
    padding: 10px 14px;
  }
  
  .successBtn{
    font-size: var(--app-extra-small-font-size) !important;
  }
  
  .successSmallBtn{
    padding: 10px 14px;
    font-size: var(--app-extra-small-font-size) !important;
  }
  
  .warningBtn{
    font-size: var(--app-extra-small-font-size) !important;
  }
  
  .dangerBtn{
    font-size: var(--app-extra-small-font-size) !important;
  }
}