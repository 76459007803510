// selected colors by user
$selected-primary-color: var(--selected-primary-color);

.bodyRow {
  margin-left: 0px !important;
  margin-right: 0px !important;
  height: 100vh;
}

.descriptionBox {
  background: $selected-primary-color;
  padding: 0.3% 0.5%;
}

.companySecondaryHeaderBox{
  display: none;
}

.submissionFormView {
  padding: 2% 3%;
}

.descriptionInnerBox {
  border-radius: 10px;
  background: var(--app-base-white-color);
  height: 100%;
  padding: 5% 8% 0;
}

.vacancyPosition {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-xx-extra-large-font-size);
  color: var(--app-base-black-color);
  margin-top: 5%;
  padding: 0 3%;
}

.pageTopic {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-xx-extra-large-second-font-size);
  color: var(--app-base-black-color);
  text-align: center;
  margin-top: 1em;
}

.pageGuidlineTopic {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-x-extra-large-font-size);
    color: var(--app-gray-700-color);
    text-align: left;
    margin-top: 1em;
    flex-direction: row;
    p{
        margin-bottom: 0.5rem;
    }
}

.guidlineTopicDiv{
  border-bottom: 1px solid var(--app-gray-200-color);
}

.guidelineFistcol{
  margin: 0px;
  padding: 0px;
}

.pagePositionLabel {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-primary-font-size);
  color: #828282;
}

.prograssBar {
  height: 0.5rem !important;
  div {
    background-color: $selected-primary-color !important;
  }
}

.subTopic {
  font-family: var(--app-primary-font-style);
  font-size: 20px;
  font-weight: var(--app-bold-font-weight);
  color: var(--app-base-black-color);
  margin-bottom: 2%;
}

.companyName {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-xx-extra-large-font-size);
  color: var(--app-base-black-color);
  text-transform: uppercase;
}

.toggleContainer {
  display: flex;
  position: sticky;
  justify-content: space-around;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: #eeeeee;
}

.toggleActive{
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: $selected-primary-color;
}

.subtopicToggle {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-semi-small-font-size);
  color: var(--app-base-black-color);
  padding: 0px 7px;
  cursor: pointer;
}

.subTopicMainBody{
  margin-top: 1%;
  margin-bottom: 20%;
  height: 57vh;
  overflow-y: auto;
  padding-left: 0;
}

.subTopicHeader{
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-large-font-size);
  color: var(--app-base-black-color);
}

.subTopicValueContainer{
  display: flex;
  word-break: break-word;
  text-align: justify;
  margin-bottom: 2%;
}

.subTopicValueIcon{
  margin-right: 2%;
  position: relative;
  top: 5px;
  color:var(--app-gray-500-color);
  font-size: 7px;
}

.subTopicValues{
  font-family: var(--app-primary-font-style);
  font-weight: var( --app-regular-font-weight);
  font-size: var( --app-primary-font-size);
  color: var(--app-gray-500-color);
}

.successBox {
  margin-top: 5%;
  margin-bottom: 10%;
  padding: 1em 1em;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.successMessage {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-large-font-size);
  color: #269625;
}

.candidatenotice {
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  text-align: left;
  font-weight: var(--app-regular-font-weight);
  color: var(--app-gray-600-color);
}

.companyNameMessage {
  font-weight: var(--app-bold-font-weight);
}

//Overlay Loading

.spanner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 0;
  background: var(--app-primary-700-color);
  width: 100%;
  display: block;
  text-align: center;
  height: 100%;
  color: var(--app-base-white-color);
  transform: translateY(-50%);
  z-index: 1000;
  visibility: hidden;

  p {
    font-size: var(--app-xx-extra-large-font-size);
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  overflow: hidden;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: var(--app-base-white-color);
  font-size: var(--app-large-font-size);
  margin: 350px auto -50px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
.loaderText{
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-xxx-extra-large-font-size);
}

.footerCreditBox {
  width: 100%;
  font-size: var(--app-small-font-size);
  color: var(--app-gray-500-color);
  font-family: var(--app-primary-font-style);
  text-align: center;
  padding: 0 2rem;
}
.footerCreditBoxInner{
  padding:0.25rem;
}
.footerCreditLink{
  color: var(--app-primary-600-color); 
  text-decoration: none;
  font-size: var(--app-semi-small-font-size);
}

.footerCreditLink:hover{
  color: var(--app-primary-700-color); 
}

.assessmentContent{
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  color: var(--app-gray-600-color);
  font-size: var( --app-large-font-size); 
}

.downloadLinkLong{
  color: var(--app-primary-600-color);
  cursor: pointer;
}

.downloadLinkLong:hover{
  color: var(--app-primary-700-color);
}
// .uploadFileHeader{
//   margin-top: 50px;
//   margin-bottom: 50px;
//   font-family: var(--app-primary-font-style);
//   font-weight: var(--app-bold-font-weight);
//   color: var(--app-base-black-color);
//   font-size: var(--app-extra-large-font-size); 
// }

.submitButton{
  margin-top: 2rem;
}

.submitTextCol {
  align-items: center;
  padding-right: 0;
  padding-left: 0;
  margin-top: 1.8%;
}

.signupBottomMessage {
  font-family: var(--app-primary-font-style);
  font-size: var(--app-small-font-size);
  font-weight: var(--app-regular-font-weight);
  color: var(--app-gray-500-color);
  text-align: center;
}

.termsPara {
  text-align: center;
  font-family: var(--app-primary-font-style);
  font-size: var(--app-small-font-size);
  color: var(--app-gray-500-color);
  a {
      font-family: var(--app-primary-font-style);
      font-size: var(--app-small-font-size);
      text-decoration: none;
      color: var(--app-gray-500-color);
      font-weight: var(--app-bold-font-weight);
      text-align: center;
      margin-bottom: 0px !important;
  }
  margin-top: -17px;
}

.timeleft{
  font-family: var(--app-primary-font-style);
  font-size: var(--app-x-extra-large-font-size);
  font-weight: var( --app-bold-font-weight);
  color: var(--app-success-500-color);

}

.Guidelines{
  display: flex;
  flex-direction: row;
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  color: var(--app-gray-600-color);
  font-size: var(--app-primary-font-size);
}
.GuidelinesRow{
  flex-direction: row;
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  color: var(--app-gray-600-color);
  font-size: var(--app-large-font-size);
  margin-left: 0px;
}

.companyLogo{
  max-width: 100%;
  max-height: 248px;
}

.guidlinecontentdiv{
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}

.guidlinecontentMaindiv{
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.guidelinecontentCol{
  flex-direction: column;
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-semi-bold-font-weight);
  color: var(--app-gray-600-color);
  font-size: var(--app-primary-font-size);
}

.importantTag{
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  color: var(--app-error-400-color);
  font-size: var(--app-primary-font-size);
  margin-top: 1rem;
}

.guidelineLinks{
  color: var(--app-primary-600-color); 
  text-decoration: none;
}

.nameTag{
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  color: var(--app-gray-600-color);
  font-size: var(--app-primary-font-size);
  margin: 0px;
  padding-left: 10px;
}

.nameTagValue{
  color: var(--app-gray-600-color);
  font-family: var(--app-primary-font-style);
  font-size: var(--app-regular-font-weight);
  font-style: normal;
  font-weight: var(--app-regular-font-weight);
  line-height: 20px; /* 142.857% */
}

.assessmentMain{
  color: var(--app-gray-600-color);
  text-align: center;
  /* Text sm/Regular */
  font-family: var(--app-primary-font-style);
  font-size: var(--app-regular-font-weight);
  font-style: normal;
  font-weight: var(--app-regular-font-weight);
  line-height: 20px; /* 142.857% */
  margin-bottom: 3rem;
}
.alertDiv{
  padding-bottom: 0;
}

.dragAndDropDiv{
  padding-bottom: 20px;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.show {
  visibility: visible;
}

.spanner,
.overlay {
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.spanner.show,
.overlay.show {
  opacity: 1;
}
//new----------------------------
.card {
  border: 1px solid var(--app-gray-500-color);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  background-color: var(--app-gray-100-color);
}

.documentName {
  color: var(--app-gray-600-color);
  font-family: var(--app-primary-font-style);
  font-size: var(--app-regular-font-weight);
  font-style: normal;
  font-weight: var(--app-bold-font-weight);
  line-height: 20px;
}

.downloadLink{
  color: var(--app-gray-600-color);
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-style: normal;
  font-weight: var(--app-bold-font-weight);
  line-height: 20px;
  text-decoration: none !important;

}
.downloadLinkDownload{
  color: var(--app-gray-600-color);
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-style: normal;
  font-weight: var(--app-bold-font-weight);
  line-height: 20px;
  margin-left: 8px;
  cursor: pointer;
  svg {
    path{
      stroke: var(--app-gray-600-color);
    }
  }
}
.downloadLinkDownload:hover{
  color: var(--app-primary-600-color);
  svg {
    path{
      stroke: var(--app-primary-600-color);
    }
  }
}
.downloadLinkLong {
  color: var(--app-gray-600-color);
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-style: normal;
  font-weight: var(--app-bold-font-weight);
  line-height: 20px;
}

.downloadLinkLong {
  color: var(--app-gray-500-color);
  display: inline-block;
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.downloadLink:hover {
  text-decoration: underline;
}

.documentDownloadLink {
  margin-top: 8px;
}
.downloadLinkBox{
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.defaultCheckBox{
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-style: normal;
  font-weight: var(--app-semi-bold-font-weight);
  line-height: 20px;
}

.accordianMain {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.cardQuestionHeader{
  background-color: var(--app-gray-100-color) !important;
  padding: 0.5rem;
}

.accordianBody{
  background-color: var(--app-gray-25-color);
  border-radius: 5px;
}
.Accordionheader{
  display: flex;
  align-items: center;
  gap: 1rem;
}
.headerName{
  display: flex;
  align-items: center;
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-style: normal;
  font-weight: var(--app-bold-font-weight);
  color: var(--app-gray-700-color);
  margin-bottom: 0 !important;
}
.documentNumber{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background-color: var(--app-gray-300-color);
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-style: normal;
  font-weight: var(--app-semi-bold-font-weight);
  line-height: 20px;
  color: var(--app-gray-700-color);
}

.accordianCardError{
  border: 1px solid var(--app-error-300-color) !important;
  box-shadow: none;
  }
.cardHeader{
  background-color: var(--app-gray-100-color);
  border-radius: 4px;
  .accordion-button:not(.collapsed){
    padding: 0 !important;
  }
  .accordion-button::after{
    margin: 0 !important;
  }
}
.JobTitleHeader{
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-weight: var(--app-bold-font-weight);
  line-height: 20px;
  color: var(--app-gray-700-color);
}
.JobTitleValue{
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-weight: var(--app-bold-font-weight);
  line-height: 20px;
  color: var(--app-gray-500-color);
}
.JobTitleDiv{
  margin-top: 3rem;
  border-bottom: 1px solid var(--app-gray-200-color);
  margin-bottom: 1.5rem;
  padding: 0;
}

@media only screen and (max-width: 600px) {

  .descriptionBox {
    margin-top: 1em;
  }
  .descriptionInnerBox {
    border-radius: 0;
  }
  .companyLogo{
    display: none;
  }

  .companySecondaryHeaderBox{
    display: block;
    margin-top: 3em;
  }

  .mainContainer{
    padding-left: 25px;
    padding-right: 25px;
  }

  .subTopicMainBody{
    min-height: 57vh;
  }

  .vacancyPosition{
    font-size: var(--app-regular-large-font-size);
    padding: 5px 0;
    color: var(--app-gray-500-color);
  }

  .pageTopic{
    font-size: var(--app-regular-large-font-size);
  }

  .moveToJobDetailsButton{
    color: var(--app-base-black-color);
    background-color: #fff;
    border: 1px solid var(--app-base-black-color);
    padding: 4px 10px;
  }
}
.errorMessage{
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-regular-font-weight);
  font-size: var(--app-semi-small-font-size);
  color: var(--app-error-600-color);
}
.mobileView{
  display:none;
}
@media only screen and (max-width: 1000px){
  .descktopView{
      display: none !important;
  } 
  .mobileView{
      display:block;
      margin-top: 1rem;
      padding-left: 1rem;
  }
  .Guidelines{
      display: flex;
      flex-direction: column;
  }
  .nameTag{
      margin: 0;
      padding-left: 0;
  }
  p{
      margin-bottom: 0.75rem;
    }
  .responseStateDiv{
      margin-top: 0.5rem;
  }
  .dividerDiv{
      margin-bottom: 0.5rem;
  }
}
@media only screen and (max-width: 767px){
  .footerCreditBox{
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}