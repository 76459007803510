.successBtn{
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  border: 1px solid var(--app-success-50-color);
  background: var(--app-success-50-color);
  font-size: var(--app-primary-font-size);
  font-weight: var(--app-regular-font-weight);
  color: var(--app-success-700-color);
  stroke: var(--app-success-700-color);
  &:hover {
    color: var(--app-success-700-color) !important;
    stroke: var(--app-success-700-color) !important;
    font-weight: var(--app-bold-font-weight)  !important;
    background-color: var(--app-success-100-color)  !important;
    border: 1px solid var(--app-success-100-color)  !important;
    box-shadow: none !important;
  }
  &:focus {
    color: var(--app-success-700-color) !important;
    stroke: var(--app-success-700-color) !important;
    font-weight: var(--app-bold-font-weight)  !important;
    background-color: var(--app-success-100-color)  !important;
    border: 1px solid var(--app-success-100-color)  !important;
    box-shadow: none !important;
  }

  &:active {
    color: var(--app-success-700-color) !important;
    stroke: var(--app-success-700-color) !important;
    font-weight: var(--app-bold-font-weight)  !important;
    background-color: var(--app-success-100-color)  !important;
    border: 1px solid var(--app-success-100-color)  !important;
    box-shadow: none !important;
  }
}

.secondaryBtn{
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  border: 1px solid var(--app-primary-50-color);
  background: var(--app-primary-50-color);
  font-size: var(--app-primary-font-size);
  font-weight: var(--app-regular-font-weight);
  color: var(--app-primary-700-color);
  stroke: var(--app-primary-700-color);

  &:hover {
    color: var(--app-primary-700-color) !important;
    stroke: var(--app-primary-700-color) !important;
    font-weight: var(--app-bold-font-weight)  !important;
    background-color: var(--app-primary-100-color)  !important;
    border: 1px solid var(--app-primary-100-color)  !important;
    box-shadow: none !important;
  }
  &:focus {
    color: var(--app-primary-700-color) !important;
    stroke: var(--app-primary-700-color) !important;
    font-weight: var(--app-bold-font-weight)  !important;
    background-color: var(--app-primary-100-color)  !important;
    border: 1px solid var(--app-primary-100-color)  !important;
    box-shadow: none !important;
  }

  &:active {
    color: var(--app-primary-700-color) !important;
    stroke: var(--app-primary-700-color) !important;
    font-weight: var(--app-bold-font-weight)  !important;
    background-color: var(--app-primary-100-color)  !important;
    border: 1px solid var(--app-primary-100-color)  !important;
    box-shadow: none !important;
  }
}

.warningBtn{
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  border: 1px solid var(--app-warning-50-color);
  background: var(--app-warning-50-color);
  font-size: var(--app-primary-font-size);
  font-weight: var(--app-regular-font-weight);
  color: var(--app-warning-700-color);
  stroke: var(--app-warning-700-color);

  &:hover {
    color: var(--app-warning-700-color) !important;
    stroke: var(--app-warning-700-color) !important;
    font-weight: var(--app-bold-font-weight)  !important;
    background-color: var(--app-warning-100-color)  !important;
    border: 1px solid var(--app-warning-100-color)  !important;
    box-shadow: none !important;
  }
  &:focus {
    color: var(--app-warning-700-color) !important;
    stroke: var(--app-warning-700-color) !important;
    font-weight: var(--app-bold-font-weight)  !important;
    background-color: var(--app-warning-100-color)  !important;
    border: 1px solid var(--app-warning-100-color)  !important;
    box-shadow: none !important;
  }

  &:active {
    color: var(--app-warning-700-color) !important;
    stroke: var(--app-warning-700-color) !important;
    font-weight: var(--app-bold-font-weight)  !important;
    background-color: var(--app-warning-100-color)  !important;
    border: 1px solid var(--app-warning-100-color)  !important;
    box-shadow: none !important;
  }
}

.dangerBtn{
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  border: 1px solid var(--app-error-50-color);
  background: var(--app-error-50-color);
  font-size: var(--app-primary-font-size);
  font-weight: var(--app-regular-font-weight);
  color: var(--app-error-700-color);
  stroke: var(--app-error-700-color);

  &:hover {
    color: var(--app-error-700-color) !important;
    stroke: var(--app-error-700-color) !important;
    font-weight: var(--app-bold-font-weight)  !important;
    background-color: var(--app-error-100-color)  !important;
    border: 1px solid var(--app-error-100-color)  !important;
    box-shadow: none !important;
  }
  &:focus {
    color: var(--app-error-700-color) !important;
    stroke: var(--app-error-700-color) !important;
    font-weight: var(--app-bold-font-weight)  !important;
    background-color: var(--app-error-100-color)  !important;
    border: 1px solid var(--app-error-100-color)  !important;
    box-shadow: none !important;
  }

  &:active {
    color: var(--app-error-700-color) !important;
    stroke: var(--app-error-700-color) !important;
    font-weight: var(--app-bold-font-weight)  !important;
    background-color: var(--app-error-100-color)  !important;
    border: 1px solid var(--app-error-100-color)  !important;
    box-shadow: none !important;
  }
}

.disabledBtn{
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  border: 1px solid var(--app-gray-100-color);
  background: var(--app-gray-100-color);
  font-size: var(--app-primary-font-size);
  font-weight: var(--app-regular-font-weight);
  color: var(--app-gray-600-color);
  stroke: var(--app-gray-600-color);

  &:hover {
    color: var(--app-gray-600-color) !important;
    stroke: var(--app-gray-600-color) !important;
    font-weight: var(--app-bold-font-weight)  !important;
    background-color: var(--app-gray-100-color)  !important;
    border: 1px solid var(--app-gray-100-color)  !important;
    box-shadow: none !important;
  }
  &:focus {
    color: var(--app-gray-600-color) !important;
    stroke: var(--app-gray-600-color) !important;
    font-weight: var(--app-bold-font-weight)  !important;
    background-color: var(--app-gray-100-color)  !important;
    border: 1px solid var(--app-gray-100-color)  !important;
    box-shadow: none !important;
  }

  &:active {
    color: var(--app-gray-600-color) !important;
    stroke: var(--app-gray-600-color) !important;
    font-weight: var(--app-bold-font-weight)  !important;
    background-color: var(--app-gray-100-color)  !important;
    border: 1px solid var(--app-gray-100-color)  !important;
    box-shadow: none !important;
  }
}
