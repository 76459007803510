$selected-primary-color: var(--selected-primary-color);
.loadingDiv {
  margin-top: 25%;
}

.questioLoadingDiv {
  padding-top: 7em;
  background: rgba(45, 166, 192, 0.15);
  border-radius: 6px;
  height: 18em;
}

.bodyRow {
  margin-left: 0px !important;
  margin-right: 0px !important;
  height: 100vh;
}

.multipleAnswers{
  margin-left: 0%;
  padding-left: 0%;
  margin-top: 0.6rem;
}

.pageTopicCompanyName {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-xxx-extra-large-font-size);
  color: var(--app-base-black-color);
  text-align: center;
  margin-top: 5rem;
}
.pageTopic {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-x-extra-large-font-size);
    color: $selected-primary-color;
    text-align: center;
    margin-bottom: 5rem;
  }

  .countDownRunning{
        font-family: var(--app-primary-font-style);
        font-size: var(--app-x-extra-large-font-size);
        font-weight: var( --app-bold-font-weight);
        color: var(--app-success-500-color);    
  }

  .countDownWarning {
    font-family: var(--app-primary-font-style);
    font-size: var(--app-x-extra-large-font-size);
    font-weight: var( --app-bold-font-weight);
    color: var(--app-warning-400-color);    
  }

  .countDownHighWarning {
    font-family: var(--app-primary-font-style);
    font-size: var(--app-x-extra-large-font-size);
    font-weight: var( --app-bold-font-weight);
    color: var(--app-error-400-color);    
  }

  .countDownPaused{
    font-family: var(--app-primary-font-style);
    font-size: var(--app-x-extra-large-font-size);
    font-weight: var( --app-bold-font-weight);
    color: var(--app-gray-300-color);    
}

  .question{
    font-family: var(--app-primary-font-style);
    font-size: var(--app-extra-large-font-size);
    font-weight: var( --app-main-bold-weight);
    color: var(--app-base-black-color);  
  }

  .questionBox{
    border-radius: 5px;
    border: 1px solid var(--app-gray-300-color);
    height: 100%;
    justify-content: center;
    padding-right: 1rem
    
  }

  .quesNo{
    font-family: var(--app-primary-font-style);
    font-size: var(--app-large-font-size);
    font-weight: var(--app-bold-font-weight);
    color: var(--app-base-black-color); 
  }

  .questionText{
    font-family: var(--app-primary-font-style);
    font-size: var(--app-large-font-size);
    font-weight: var(--app-semi-bold-font-weight);
    color: var(--app-base-black-color); 
  }

  .ButtonRow{
    margin-top: 20px;  
  }

  .numberRow{
    display: flex;
    justify-content: right;
    border-radius: 5px;
    border: 1px solid var(--app-gray-300-color);
    background: var(--app-primary-50-color);
    padding-right: 17px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .numberRowText{
    color: var(--app-gray-600-color);
    font-family: var(--app-primary-font-style);
    font-size: var(--app-large-font-size);
    font-style: normal;
    font-weight: var(--app-bold-font-weight);
    line-height: 20px; /* 125% */
    margin: 0px;
    padding: 0px;
  }

  .numberRowSvg{
    stroke: var(--app-gray-600-color);
    margin-top: 2px;
  }
  .radioBtns input {
    margin-left: 30px;
    cursor: pointer;
    &:checked {
      background-color: var(--app-primary-600-color) !important;
      border-color: var(--app-primary-600-color) !important;
      box-shadow: none !important;
    }
  }


  .errorMessage{
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-regular-font-weight);
    font-size: var(--app-semi-small-font-size);
    color: var(--app-error-700-color);
    margin-top: 2%;
  }

  .questionNameDiv{
    border-radius: 5px;
    background: var(--app-primary-50-color);
    padding-left: 17px;
    padding-top: 17px;
    padding-bottom: 17px;
    margin-bottom: 0.5rem;
  }

  .questionNameText{
    color: var(--app-gray-600-color);
    font-family: var(--app-primary-font-style);
    font-size: var(--app-large-font-size);
    font-style: normal;
    font-weight: var(--app-bold-font-weight);
    line-height: 20px; /* 125% */
    margin: 0px;
    padding: 0px;
  }

  .answerMainDiv{
    width: fit-content;
    padding-right: 0px;
  }
  .questionTextMainDiv{
    margin-top: 1rem;
    margin-left: 2.8rem;
  }

  .ButtonSecondRow{
    margin-right: 1px;
  }
  @media only screen and (max-width: 600px) {
    .ButtonSecondRow{
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     margin-left: 2px;
    }
    .loadingDiv {
      margin-left: 10%;
    }
    .loaderSecond{
      margin-left: 35px;
    }
    .quizBox{
      padding-left: 25px;
      padding-right: 25px;
    }
    .answerMainDiv{
      width: fit-content;
      margin-left: -15px;
      padding-left: 0px;
    }
    .questionBox{
      margin-left: 0px;
      padding-left: 0px;
    }

    .questionText{
      margin-left: 0px;
      padding-left: 15px;
    }

    .questionTextMainDiv{
      margin-top: 1rem;
      margin-left: 0px;
      padding-left: 0px;
    }
  }