.container{   
    // margin-top: 20%;
    display:flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    
}
.content{
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-xx-extra-large-font-size);
    color: var(--app-base-black-color);
    text-align: center;
}

.contentExpired{
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-xx-extra-large-font-size);
    color: var( --app-error-700-color);
    text-align: center;
}

.contentSubmitted{
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-xx-extra-large-font-size);
    color: var( --app-error-700-color);
    text-align: center;
}