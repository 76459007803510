.defaultIcon {
  display: flex;
  width: 50px;
  height: 50px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  border: 8px solid var(--app-primary-50-color);
  background:var(--app-primary-100-color);
  color:var(--app-primary-600-color);
  stroke:var(--app-primary-600-color);
}

.successIcon {
  display: flex;
  width: 50px;
  height: 50px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  border: 8px solid var(--app-success-50-color);
  background:var(--app-success-100-color);
  color:var(--app-success-600-color);
  stroke:var(--app-success-600-color);
}

.warningIcon {
  display: flex;
  width: 50px;
  height: 50px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  border: 8px solid var(--app-warning-50-color);
  background:var(--app-warning-100-color);
  color:var(--app-warning-600-color);
  stroke:var(--app-warning-600-color);
}

.dangerIcon {
  display: flex;
  width: 50px;
  height: 50px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  border: 8px solid var(--app-error-50-color);
  background:var(--app-error-100-color);
  color:var(--app-error-600-color);
  stroke:var(--app-error-600-color);
}

.squareOutlinedIcon {

  border-radius: 10px;
  border: 1px solid var(--app-gray-200-color);
  background: var(--app-base-white-color);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px var(--app-gray-100-color);

  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  color:var(--app-gray-600-color);
  stroke:var(--app-gray-600-color);
}



