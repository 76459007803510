// selected colors by user
$selected-primary-color: var(--selected-primary-color);

.bodyRow {
  margin-left: 0px !important;
  margin-right: 0px !important;
  height: 100vh;
}

.descriptionBox {
  background: $selected-primary-color;
  padding: 0.3% 0.5%;
}

.companySecondaryHeaderBox {
  display: none;
}

.submissionFormView {
  padding: 2%;
}

.descriptionInnerBox {
  border-radius: 10px;
  background: var(--app-base-white-color);
  height: 100%;
  padding: 5% 8% 0;
}

.vacancyPosition {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-xx-extra-large-font-size);
  color: var(--app-base-black-color);
  margin-top: 5%;
  padding: 0 3%;
}

.accordianMainHeaderTopic {
  margin-bottom: 0.5rem !important;
  margin-right: 3rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 1rem;
}

.errorMessage{
  font-family: var(--app-primary-font-style);
  font-size: var(--app-small-font-size);
  font-weight: var(--app-regular-font-weight);
  color: var(--app-error-700-color);
  margin-top: 0;
}

.SecContainer{
  border: 1.5px solid var(--app-gray-200-color);
  border-radius: 1%;
  margin-top: 1rem;
  padding-bottom: 0.5rem;
}

.pageTopic {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-xxx-extra-large-font-size);
  color: var(--app-base-black-color);
  text-align: center;
  margin-top: 1rem;
}

.mainContainer {
  padding-left: 5rem;
  padding-right: 5rem;
}

.pageGuidlineTopic {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-x-extra-large-font-size);
  color: var(--app-gray-600-color);
  text-align: left;
  margin-top: 1em;
  flex-direction: row;

  p{
    margin-bottom: 0.5rem; 
  }
}
.pageGuidlineTopicCount {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-large-font-size);
  color: var(--app-gray-600-color);
  text-align: left;
  flex-direction: row;
}

.guidlineTopicDiv {
  border-bottom: 1px solid var(--app-gray-200-color);
}
.guidlineTopicDivSecond {
  border-bottom: 1px solid var(--app-gray-200-color);
  margin-top: 3rem;
}

.pagePositionLabel {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-primary-font-size);
  color: #828282;
}

.prograssBar {
  height: 0.5rem !important;

  div {
    background-color: $selected-primary-color !important;
  }
}

.subTopic {
  font-family: var(--app-primary-font-style);
  font-size: 20px;
  font-weight: var(--app-bold-font-weight);
  color: var(--app-base-black-color);
  margin-bottom: 2%;
}

.companyName {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-xx-extra-large-font-size);
  color: var(--app-base-black-color);
  text-transform: uppercase;
}

.toggleContainer {
  display: flex;
  position: sticky;
  justify-content: space-around;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: #eeeeee;
}

.toggleActive {
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: $selected-primary-color;
}

.subtopicToggle {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-semi-small-font-size);
  color: var(--app-base-black-color);
  padding: 0px 7px;
  cursor: pointer;
}
.headerName {
  display: flex !important;
  width: fit-content;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}
.subTopicMainBody {
  margin-top: 1%;
  height: 68vh;
  overflow-y: auto;
  padding-left: 0;
}

.subTopicHeader {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-large-font-size);
  color: var(--app-base-black-color);
}

.subTopicValueContainer {
  display: flex;
  word-break: break-word;
  text-align: justify;
  margin-bottom: 2%;
}

.subTopicValueIcon {
  margin-right: 2%;
  position: relative;
  top: 5px;
  color: var(--app-gray-500-color);
  font-size: 7px;
}

.subTopicValues {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-regular-font-weight);
  font-size: var(--app-primary-font-size);
  color: var(--app-gray-500-color);
}

.successBox {
  margin-top: 5%;
  margin-bottom: 10%;
  padding: 1em 1em;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.successMessage {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-large-font-size);
  color: #269625;
}

.candidatenotice {
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  text-align: left;
  font-weight: var(--app-regular-font-weight);
  color: var(--app-gray-600-color);
}

.companyNameMessage {
  font-weight: var(--app-bold-font-weight);
}

//Overlay Loading

.spanner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 0;
  background: var(--app-primary-700-color);
  width: 100%;
  display: block;
  text-align: center;
  height: 100%;
  color: var(--app-base-white-color);
  transform: translateY(-50%);
  z-index: 1000;
  visibility: hidden;

  p {
    font-size: var(--app-xx-extra-large-font-size);
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  overflow: hidden;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: var(--app-base-white-color);
  font-size: var(--app-large-font-size);
  margin: 350px auto -50px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

.loaderText {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-xxx-extra-large-font-size);
}



.footerCreditBox {
  width: 100%;
  font-size: var(--app-small-font-size);
  color: var(--app-gray-500-color);
  font-family: var(--app-primary-font-style);
  text-align: center;
  padding: 0 2rem;
}
.footerCreditBoxInner{
  padding:0.25rem;
}

.footerCreditLink {
  color: var(--app-primary-600-color);
  text-decoration: none;
  font-size: var(--app-semi-small-font-size);
}

.footerCreditLink:hover {
  color: var(--app-primary-700-color);
}

.assessmentContent {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-regular-font-weight);
  color: var(--app-gray-600-color);
  font-size: var(--app-primary-font-size);
  border-bottom: 1px solid var(--app-gray-200-color);
}

.dragAndDropDiv {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--app-gray-200-color);
}

.assessmentMain {
  color: var(--app-gray-600-color);
  text-align: center;
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-style: normal;
  font-weight: var(--app-regular-font-weight);
  line-height: 20px;
}

.assessmentMainDiv {
  border-bottom: 1px solid var(--app-gray-200-color);
}

.submitButton {
  margin-top: 50px;
}

.submitTextCol {
  align-items: center;
  padding-right: 0;
  padding-left: 0;
  margin-top: 1.8%;
}

.signupBottomMessage {
  font-family: var(--app-primary-font-style);
  font-size: var(--app-small-font-size);
  font-weight: var(--app-regular-font-weight);
  color: var(--app-gray-500-color);
  text-align: center;
}

.termsPara {
  text-align: center;
  font-family: var(--app-primary-font-style);
  font-size: var(--app-small-font-size);
  color: var(--app-gray-500-color);
  a {
      font-family: var(--app-primary-font-style);
      font-size: var(--app-small-font-size);
      text-decoration: none;
      color: var(--app-gray-500-color);
      font-weight: var(--app-bold-font-weight);
      text-align: center;
      margin-bottom: 0px !important;
  }
  margin-top: -17px;
}

.timeleft {
  font-family: var(--app-primary-font-style);
  font-size: var(--app-x-extra-large-font-size);
  font-weight: var(--app-bold-font-weight);
  color: var(--app-success-500-color);

}

.Guidelines {
  flex-direction: row;
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  color: var(--app-gray-600-color);
  font-size: var(--app-primary-font-size);
  margin-bottom: 0;
}
.GuidelinesRow{
    flex-direction: row;
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    color: var(--app-gray-600-color);
    font-size: var(--app-large-font-size);
    margin-left: 0px;
}

.GuidelinesSecond {
  flex-direction: row;
  padding-left: 12px;
}

.guidelineFistcol {
  margin: 0px;
  padding: 0px;
}

.companyLogo {
  max-width: 100%;
  max-height: 248px;
}

.guidlinecontentdiv {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}

.guidlinecontentMaindiv {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}
.guidlinecontentSeconddiv{
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  gap:0.5rem;
}
.guidelinecontentCol {
  flex-direction: column;
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-semi-bold-font-weight);
  color: var(--app-gray-600-color);
  font-size: var(--app-primary-font-size);
  margin-bottom: 0;
}
.guidelinecontentColGuidleine {
  flex-direction: column;
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-semi-bold-font-weight);
  color: var(--app-gray-600-color);
  font-size: var(--app-primary-font-size);
}

.importantTag {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  color: var(--app-error-400-color);
  font-size: var(--app-large-font-size);
  margin-top: 1rem;
}

.guidelineLinks {
  color: var(--app-primary-600-color);
  text-decoration: none;
}
.guidelineWithdraw {
  color: var(--app-primary-600-color);
  cursor: pointer;
  text-decoration: none;
}

.nameTag {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  color: var(--app-gray-600-color);
  font-size: var(--app-primary-font-size);
}

.nameSecondTag {
  color: var(--app-gray-600-color);
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-style: normal;
  font-weight: var(--app-bold-font-weight);
  line-height: 20px;
  /* 142.857% */
}



.nameTagValue {
  color: var(--app-gray-600-color);
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-style: normal;
  font-weight: var(--app-regular-font-weight);
  line-height: 20px;
  width: 18rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal; 
  /* 142.857% */
}
.accordianBody {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  width: 100% !important;
  align-items: center !important;
}


@-webkit-keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.show {
  visibility: visible;
}

.spanner,
.overlay {
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.spanner.show,
.overlay.show {
  opacity: 1;
}

.assessmentDetailsMain {
  color: var(--app-gray-600-color);
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-style: normal;
  font-weight: var(--app-bold-font-weight);
  line-height: 20px;
  /* 142.857% */
}
.cardQuestionHeader {
  background-color: var(--app-primary-50-color);
  //padding: 0.45rem 0.75rem;
  cursor: pointer;
}
.accordianHeaderTopic{
  color: var(--app-gray-600-color);
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-style: normal;
  font-weight: var(--app-bold-font-weight);
  line-height: 20px;
  margin-bottom: 0;
  width: 18rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal; 
}
.accordianCard{
  margin-bottom: 1rem;
}
.deleteButton {
  margin-right: 2rem;
}
@media only screen and (max-width: 1400px){
  .mainContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media only screen and (max-width: 600px) {

  .descriptionBox {
    margin-top: 1em;
  }

  .descriptionInnerBox {
    border-radius: 0;
    padding: 5% 12% 0;
  }

  .mainContainer {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
  }
  .SecContainer{
    padding-left: 0;
    padding-right: 0;
  }

  .companySecondaryHeaderBox {
    display: block;
    margin-top: 3em;
  }

  .companyLogo {
    display: none;
  }

  .subTopicMainBody {
    min-height: 57vh;
  }

  .headerDetails {
    display: flex;
    align-items: center;
    flex-basis: auto;
    width: 100%;
    margin-left: 2rem;
    margin-right: 3rem;
  }

  .cardHeader {
    background: #269625 !important;
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-primary-font-size);
    color: var(--app-base-white-color) !important;
    margin-right: -0.5px;
    border: none;

    button {
      background-color: var(--app-primary-700-color);
      color: var(--app-base-white-color);
      font-family: var(--app-primary-font-style);
      font-weight: var(--app-bold-font-weight);
      font-size: var(--app-primary-font-size);
    }
  }

  .cardQuestionHeader {
    background: transparent;
    //padding: 0.45rem 0.75rem;
    cursor: pointer;
  }

  .actionButtons {
    margin-top: -5px;
    margin-left: 5rem;
    background-color: transparent !important;
    border: none !important;
  }

  .accordianHeaderTopic {
    margin-bottom: 0px !important;
    margin-right: 3rem;
    width: fit-content;
    margin-top: 0.5rem;
  }

  .accordianMain {
    border: 2px solid red !important;
    border-radius: 8px !important;
    margin-bottom: 2% !important;
  }

  .deleteButton {
    width: fit-content;
    margin-right: 0;
  }

  .accordianCard {
    border: 1px solid var(--app-primary-700-color);
    border-radius: 6px;
    margin-right: -0.5px;
    margin-top: -0.5px;
    margin-left: -0.5px;
    margin-bottom: 0;
  }

  .defaultLabel {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-primary-font-size);
    color: var(--app-base-black-color);
    margin-top: 0;
    margin-bottom: 0.35rem
  }

  .vacancyPosition {
    font-size: var(--app-regular-large-font-size);
    padding: 5px 0;
    color: var(--app-gray-500-color);
  }

  .pageTopic {
    font-size: var(--app-regular-large-font-size);
  }

  .moveToJobDetailsButton {
    color: var(--app-base-black-color);
    background-color: #fff;
    border: 1px solid var(--app-base-black-color);
    padding: 4px 10px;
  }
  .accordianBody {
    flex-direction: column-reverse !important;
  }
  .nameTag{
    display: flex;
    flex-direction: column;
  }
    .nameTagValue {
      width: 15rem !important;
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: normal;
    }
}

.inputRow{
  display: flex;
}
@media only screen and (max-width: 1500px){
  .inputRow{
    display: flex;
    flex-direction: column;
  }
}
.mobileView{
  display:none;
}
@media only screen and (max-width: 1000px){
  .descktopView{
      display: none !important;
  } 
  .mobileView{
      display:block;
      padding-top: 1rem;
      padding-left: 1rem;
  }
  .Guidelines{
      display: flex;
      flex-direction: column;
  }
  .nameTag{
      margin: 0;
  }
  .nameTagSecondValue{
    padding: 0;
  }
  p{
    margin-bottom: 0.75rem;
  }
}
@media only screen and (max-width: 767px){
  .footerCreditBox{
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}