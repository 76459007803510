.dangerAlert{
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-primary-font-size);
  color: var(--app-error-700-color);
  stroke: var(--app-error-700-color) ;
  background: var(--app-error-50-color);
  border-radius: 6px;
  padding: 0.50rem 0.75rem;
  border: none !important;
  text-align: justify;
  border-color: var(--app-error-50-color) !important;
}

.warningAlert{
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-primary-font-size);
  color: var(--app-warning-700-color);
  stroke: var(--app-warning-700-color) ;
  background: var(--app-warning-50-color);
  border-radius: 6px;
  padding: 0.50rem 0.75rem;
  border: none !important;
  text-align: justify;
  border-color: var(--app-warning-50-color) !important;  
}

.successAlert {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-primary-font-size);
  color: var(--app-success-700-color);
  stroke: var(--app-success-700-color) ;
  background: var(--app-success-50-color);
  border-radius: 6px;
  padding: 0.50rem 0.75rem;
  border: none !important;
  text-align: justify;
  border-color: var(--app-success-50-color) !important; 
}


.defaultAlert {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-primary-font-size);
  color: var(--app-primary-700-color);
  stroke: var(--app-primary-700-color) ;
  background: var(--app-primary-50-color);
  border-radius: 6px;
  padding: 0.50rem 0.75rem;
  border: none !important;
  text-align: left;
  border-color: var(--app-primary-50-color) !important; 
}

.infoIcon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  margin-bottom: 2px;
}


.linkDeafult{
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-primary-font-size);

  color: var(--app-primary-700-color) !important;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    color: var(--app-primary-800-color) !important;
  }
}
