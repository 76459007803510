.incommingMessage {
  background-color: var(--app-base-white-color);
  color: var(--app-gray-600-color);
  margin: 0.5rem;
  margin-right: 2rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding: 0;
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-primary-font-size);
  text-align: start;
  border-radius: 10px 10px 10px 0;

  .senderName{
    font-weight: var(--app-semi-bold-font-weight);
    font-size: var(--app-extra-small-font-size);
    font-weight: var(--app-bold-font-weight);
    color: var(--app-gray-600-color);
    margin-bottom: 0;
}

  .bodyMessage {
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-semi-small-font-size);
    margin-bottom: 0.2rem;
  }

  .sentTime {
    font-weight: var(--app-regular-font-weight);
    font-size: 9px;
    float: right;
  }
}

.outgoingMessage {
  background-color: var(--app-primary-500-color);
  color: var(--app-gray-900-color);
  margin: 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-left: 2rem;
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-primary-font-size);
  border-radius: 10px 10px 0 10px;

  .senderName{
    font-weight: var(--app-semi-bold-font-weight);
    font-size: var(--app-extra-small-font-size);
    font-weight: var(--app-bold-font-weight);
    color: var(--app-gray-600-color);
    margin-bottom: 0;
}

  .bodyMessage {
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-semi-small-font-size);
    margin-bottom: 0.2rem;
  }

  .sentTime {
    font-weight: var(--app-regular-font-weight);
    font-size: 9px;
    float: right;
  }
}

.messageBox{
  padding: 0.5rem;
}
