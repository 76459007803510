.modalBodyWrapper{
  padding: 24px;
}

.modalCancelBtnDiv {
  float: right;

  .modalCancelBtn {
    border-radius: 6px;
    background: var(--app-base-white-color) !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0.375rem 0.375rem;
    line-height: 1;
    stroke: var(--app-gray-500-color);
  
    &:hover {
      stroke: var(--app-gray-600-color);
    }
  
    &:focus {
      stroke: var(--app-gray-600-color);
    }
  
    &:active {
      stroke: var(--app-gray-600-color);
    }
  }
}

.modalHeaderIconDiv{
  float: left;
}

.modalHeaderTopic {
  color: var(--app-gray-900-color);
  font-size: var(--app-x-extra-large-font-size);
  font-style: normal;
  font-weight: var(--app-bold-font-weight);
  line-height: 28px; 
  margin-top: 16px;
  margin-bottom: 0;
}

.modalHeaderSubTopic{
  color: var(--app-gray-600-color);
  font-size: var(--app-primary-font-size);
  font-style: normal;
  font-weight: var(--app-regular-font-weight);
  line-height: 20px;
}

.modalActionBtnRow{
  margin-top: 1.4rem;
}

.modalMsgLink{
  stroke: var(--app-primary-700-color);

  a {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-primary-font-size);
    text-decoration: none;
  
    color: var(--app-primary-700-color) !important;
    margin-left: 10px;
    cursor: pointer;
  
    &:hover {
      color: var(--app-primary-800-color) !important;
    }
  }
}

