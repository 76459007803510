// selected colors by user
$selected-primary-color: var(--selected-primary-color);

.bodyRow {
    margin-left: 0px !important;
    margin-right: 0px !important;
    height: 100vh;
}

.descriptionBox {
    background: $selected-primary-color;
    padding: 0.3% 0.5%;
}

.companySecondaryHeaderBox {
    display: none;
}

.submissionFormView {
    padding: 2%;
}

.companyLogo{
    max-width: 100%;
    max-height: 248px;
    margin-bottom:1em
  }

.descriptionInnerBox {
    border-radius: 10px;
    background: var(--app-base-white-color);
    height: 100%;
    padding: 5% 8% 0;
}

.vacancyPosition {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-xx-extra-large-font-size);
    color: var(--app-base-black-color);
    margin-top: 5%;
    padding: 0 3%;
}

.pageTopic {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-xx-extra-large-second-font-size);
    color: var(--app-base-black-color);
    text-align: center;
    margin-top: 1em;
}
.pageGuidlineTopic {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-x-extra-large-font-size);
    color: var(--app-gray-700-color);
    text-align: left;
    margin-top: 1em;
    flex-direction: row;
    p{
        margin-bottom: 0.5rem;
    }
}

.pagePositionLabel {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-primary-font-size);
    color: #828282;
}

.prograssBar {
    height: 0.5rem !important;

    div {
        background-color: $selected-primary-color !important;
    }
}

.subTopic {
    font-family: var(--app-primary-font-style);
    font-size: 20px;
    font-weight: var(--app-bold-font-weight);
    color: var(--app-base-black-color);
    margin-bottom: 2%;
}

.companyName {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-xx-extra-large-font-size);
    color: var(--app-base-black-color);
    text-transform: uppercase;
}

.toggleContainer {
    display: flex;
    position: sticky;
    justify-content: space-around;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #eeeeee;
}

.toggleActive {
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-bottom-color: $selected-primary-color;
}

.subtopicToggle {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-semi-small-font-size);
    color: var(--app-base-black-color);
    padding: 0px 7px;
    cursor: pointer;
}

.subTopicMainBody {
    margin-top: 1%;
    margin-bottom: 20%;
    overflow-y: auto;
    padding-left: 0;
}

.subTopicHeader {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-large-font-size);
    color: var(--app-base-black-color);
}

.subTopicValueContainer {
    display: flex;
    word-break: break-word;
    text-align: justify;
    margin-bottom: 2%;
}

.subTopicValueIcon {
    margin-right: 2%;
    position: relative;
    top: 5px;
    color: var(--app-gray-500-color);
    font-size: 7px;
}

.subTopicValues {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-regular-font-weight);
    font-size: var(--app-primary-font-size);
    color: var(--app-gray-500-color);
}

.successBox {
    margin-top: 5%;
    margin-bottom: 10%;
    padding: 1em 1em;
    box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
}

.successMessage {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-large-font-size);
    color: #269625;
}

.candidatenotice {
    font-family: var(--app-primary-font-style);
    font-size: var(--app-primary-font-size);
    text-align: left;
    font-weight: var(--app-regular-font-weight);
    color: var(--app-gray-600-color);
}

.companyNameMessage {
    font-weight: var(--app-bold-font-weight);
}

//Overlay Loading

.spanner {
    position: absolute;
    top: 50%;
    left: 0;
    background: var(--app-primary-700-color);
    width: 100%;
    display: block;
    text-align: center;
    height: 100%;
    color: #fff;
    transform: translateY(-50%);
    z-index: 1000;
    visibility: hidden;

    p {
        font-size: 50px;
    }
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    overflow: hidden;
}

.loader,
.loader:before,
.loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
}

.loader {
    color: #ffffff;
    font-size: 10px;
    margin: 350px auto -120px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    content: "";
    position: absolute;
    top: 0;
}

.loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loader:after {
    left: 3.5em;
}

.loaderText {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-xxx-extra-large-font-size);
}


.footerCreditBox {
    width: 100%;
    font-size: var(--app-small-font-size);
    color: var(--app-gray-500-color);
    font-family: var(--app-primary-font-style);
    text-align: center;
    padding: 0 2rem;
  }
  .footerCreditBoxInner{
    padding:0.25rem;
  }

.footerCreditLink {
    color: var(--app-primary-600-color);
    text-decoration: none;
    font-size: var(--app-semi-small-font-size);
}

.footerCreditLink:hover {
    color: var(--app-primary-700-color);
}

.assessmentContent {
    text-align: center;
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    color: var(--app-gray-600-color);
    font-size: var(--app-large-font-size);
}

// .uploadFileHeader{
//   margin-top: 50px;
//   margin-bottom: 50px;
//   font-family: var(--app-primary-font-style);
//   font-weight: var(--app-bold-font-weight);
//   color: var(--app-base-black-color);
//   font-size: var(--app-extra-large-font-size); 
// }

.submitButton {
    margin-top: 50px;
    padding: 0 80px 0 80px;
}

.submitTextCol {
    align-items: center;
    padding-right: 0;
    padding-left: 0;
    margin-top: 1.8%;
}

.signupBottomMessage {
    font-family: var(--app-primary-font-style);
    font-size: var(--app-small-font-size);
    font-weight: var(--app-regular-font-weight);
    color: var(--app-gray-500-color);
    text-align: center;
}

.termsPara {
    text-align: center;
    font-family: var(--app-primary-font-style);
    font-size: var(--app-small-font-size);
    color: var(--app-gray-500-color);
    a {
        font-family: var(--app-primary-font-style);
        font-size: var(--app-small-font-size);
        text-decoration: none;
        color: var(--app-gray-500-color);
        font-weight: var(--app-bold-font-weight);
        text-align: center;
        margin-bottom: 0px !important;
    }
    margin-top: -17px;
  }
  
.timeleft {
    font-family: var(--app-primary-font-style);
    font-size: var(--app-x-extra-large-font-size);
    font-weight: var(--app-bold-font-weight);
    color: var(--app-success-500-color);

}

.Guidelines {
    flex-direction: row;
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    color: var(--app-gray-600-color);
    font-size: var(--app-large-font-size);
}
.GuidelinesRow{
    flex-direction: row;
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    color: var(--app-gray-600-color);
    font-size: var(--app-large-font-size);
    margin-left: 0px;
}

.controlButtonsRow{
    flex-direction: row;
    margin-top: 3em;
}

.guidlinecontentdiv {
    display: flex;
    flex-direction: row;
    margin-bottom: 0rem;
}

.guidelinecontentCol {
    flex-direction: column;
    font-family: var(--app-primary-font-style);
    color: var(--app-gray-600-color);
    font-size: var(--app-large-font-size);
    margin-bottom: 5px;
}

.importantTag {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    color: var(--app-error-400-color);
    font-size: var(--app-large-font-size);
    margin-top: 1rem;
}

.guidelineLinks {
    color: var(--app-primary-600-color);
    text-decoration: none;
}

.nameTag {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    color: var(--app-base-black-color);
    font-size: var(--app-large-font-size);
}

.approveBtn {
    font-size: var(--app-primary-font-size);
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-regular-font-weight);
    padding: 5px 20px;
    margin-right: 3%;
}

.rejectBtn {
    font-size: var(--app-primary-font-size);
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-regular-font-weight);
    padding: 5px 20px;
}

.descColumn {
    align-self: center;
}

.responseTitle {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-xx-extra-large-font-size);
    color: var(--app-success-600-color);
    text-align: center;
}

.responseSuggestion {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-extra-large-font-size);
    opacity: 0.7;
    text-align: center;
}

.impotantLabel {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-semi-bold-font-weight);
    font-size: var(--app-large-font-size);
    color: var(--app-error-700-color);
}

.impotantNotes {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-primary-font-size);
}

.labelTopic {
    font-family: var(--app-primary-font-style);
    font-size: var(--app-large-font-size);
    font-weight: var(--app-bold-font-weight);
}

.labelValues {
    font-family: var(--app-primary-font-style);
    font-size: 15px;
    font-weight: var(--app-regular-font-weight);
    margin-left: 2%;
    opacity: 0.85;
}

.badgeType {
    border-radius: 6px;
    background: rgba(39, 174, 96, 0.15);
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-semi-small-font-size);
    color: var(--app-success-500-color);
    margin-bottom: 0px;
    margin-top: -5px;
    padding: 0.35rem 0.25rem;
    text-align: center;
    width: fit-content;
    padding: 0.3rem 1.2rem;
}

.badgeTypeWarning {
    border-radius: 6px;
    background: rgba(242, 201, 76, 0.15);
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-semi-small-font-size);
    color: var(--app-warning-700-color);
    margin-bottom: 0px;
    margin-top: -5px;
    padding: 0.35rem 0.25rem;
    text-align: center;
    width: fit-content;
    padding: 0.3rem 1.2rem;
}

.badgeTypeDanger {
    border-radius: 6px;
    background: rgba(235, 87, 87, 0.15);
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-semi-small-font-size);
    color: var(--app-error-400-color);
    margin-bottom: 0px;
    margin-top: -5px;
    padding: 0.35rem 0.25rem;
    text-align: center;
    width: fit-content;
    padding: 0.3rem 1.2rem;
}


.externalLinkkValue{
    font-family: var(--app-primary-font-style);
    font-size: var(--app-semi-small-font-size);
    font-weight: var(--app-bold-font-weight);
    color: var(--app-primary-600-color);
    margin-bottom: 2em;
    margin-left: 1em;
    padding-top: 3px;
}
.main_container{
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 100%;
    height: 300px;
    margin-bottom: 5%;
  }
  .main_col{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .mainRow{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .rightMAinCol{
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .leftMAinCol{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
  .interviewDescription{
    display: flex;
    justify-content: center;
    width: auto;
    height: auto;
  }
  .interviewDescription video{
    width: 300px;
    height: 250px;
  }
  .timer {
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .text {
    color: #aaa;
  }
  
  .value {
    font-size: 40px;
  }
  
  .info {
    max-width: 360px;
    margin: 40px auto 0;
    text-align: center;
    font-size: 16px;
  }
  .countdown_main{
    display: flex;
    justify-content: center;
    text-align: center;
  }
@-webkit-keyframes load7 {

    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

@keyframes load7 {

    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

.show {
    visibility: visible;
}

.spanner,
.overlay {
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.spanner.show,
.overlay.show {
    opacity: 1;
}


@media only screen and (max-width: 600px) {

    .descriptionBox {
        margin-top: 1em;
    }

    .descriptionInnerBox {
        border-radius: 0;
    }

    .companyPrimaryHeaderBox {
        display: none;
    }

    .companySecondaryHeaderBox {
        display: block;
        margin-top: 3em;
    }

    .subTopicMainBody {
        min-height: 57vh;
        ;
    }

    .vacancyPosition {
        font-size: var(--app-regular-large-font-size);
        padding: 5px 0;
        color: var(--app-gray-500-color);
    }

    .pageTopic {
        font-size: var(--app-regular-large-font-size);
    }

    .moveToJobDetailsButton {
        color: var(--app-base-black-color);
        background-color: #fff;
        border: 1px solid var(--app-base-black-color);
        padding: 4px 10px;
    }
}

.container{   
    // margin-top: 20%;
    display:flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.content{
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-xx-extra-large-font-size);
    color: var(--app-base-black-color);
    text-align: center;
}

@media only screen and (max-width: 767px){
    .footerCreditBox{
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }