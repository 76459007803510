.textareaLabel {
  text-align: left;
  font-family: var(--app-primary-font-style);
  color: var(--app-base-black-color);
  font-size: var(--app-primary-font-size);
  font-weight: var(--app-bold-font-weight);
}
.textareaBox {
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid var(--app-gray-300-color);
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-regular-font-weight);
  font-size: var(--app-semi-small-font-size);
  color: var(--app-base-black-color);
  height: 120px !important;
  margin-bottom:0.1rem;

  &:hover {
    border: 1px solid var(--app-primary-700-color) !important;
  }
  &:focus {
    border: 1px solid var(--app-primary-700-color) !important;
  }
  &:active{
    border: 1px solid var(--app-primary-700-color) !important;
  }
}

.remainingCount{
  font-family: var(--app-primary-font-style);
  font-size: var(--app-small-font-size);
  font-weight: var(--app-regular-font-weight);
  color: var(--app-base-black-color);
}
.errorMessage{
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-regular-font-weight);
  font-size: var(--app-semi-small-font-size);
  color: var(--app-error-700-color);
  margin-top: 0;
  margin-bottom:0.1rem;
}
