.App {
  display: flex;
  flex-direction: column;
}

.App__mediaRecorderWrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: space-around;
  align-items: center;
}

.mediaRecorderWrapper__buttons {
  display: flex;
  flex-direction: column;
  height: 40vh;
  justify-content: space-around;
}

button {
  width: "15vw";
  height: "10vh"
}

.mainbody {
  display: flex;
  justify-content: center;
}

.main_container {
  width: 90%;
  height: auto;
  justify-content: center;
  margin-top: 5%;
  padding-bottom: 60px;
}

.main_col {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.mainRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  height: 300px;
}

.rightMAinCol {
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 12px;
  border: 1px solid var(--app-gray-200-color);
  background: var(--app-base-white-color);
}

.leftMAinCol {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding-left: 0px;

}

.interviewDescription {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 300px;
  border-radius: 12px;
  background: var(--app-base-black-color);
  padding-left: 0px;
  margin-left: 0px;
}

.interviewDescription video {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.timer {
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: var(--app-base-white-color);
  /* Text sm/Medium */
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-style: normal;
  font-weight: var(--app-semi-bold-font-weight);
  line-height: 20px;
  /* 142.857% */
}

.value {
  color: var(--app-base-white-color);
  font-size: 40px;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: var(--app-large-font-size);
}

.countdown_main {
  display: flex;
  justify-content: center;
  text-align: center;
}

//new css
.topRow {
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.topRowText {
  color: var(--app-gray-900-color);
  text-align: center;
  font-family: var(--app-primary-font-style);
  font-size: var(--app-xxx-extra-large-font-size);
  font-style: normal;
  font-weight: var(--app-bold-font-weight);
  line-height: 28px;
  /* 87.5% */
  /* 87.5% */
}

.topRowSecondText {
  color: var(--app-primary-600-color);
  font-family: var(--app-primary-font-style);
  font-size: var(--app-x-extra-large-font-size);
  font-style: normal;
  font-weight: var(--app-bold-font-weight);
  line-height: 20px;
  /* 100% */
}

.mainQuestionDetailed {
  border-radius: 12px;
  border: 1px solid var(--app-gray-200-color);
  background: var(--app-base-white-color);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.mainQuestionSecondDetailed {
  border-radius: 12px;
  border: 1px solid var(--app-gray-200-color);
  background: var(--app-base-white-color);
  /* Shadow/xs */
  padding: 0px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.questionDetailedHeader {
  display: flex;
  flex: auto;
  flex-direction: row;
  justify-content: space-between;
}

.leftBottomButtonDiv {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 20px;
  margin-top: 50px;
  padding-top: 20px;
  border-top: 1px solid var(--app-gray-200-color);
}

.leftBottomSeecondButtonDiv {
  margin-right: 5px;
  padding-right: 0px;
  margin-top: 1rem;
}

.leftBottomMainButtonDiv {
  margin-right: 5px;
  padding-right: 0px;
}

.leftBottomSeecondButtonMain {
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: end;
  margin-right: 0px;
  padding-right: 0px;
}

.questionHeaderText {
  color: var(--app-gray-900-color);
  /* Text md/Medium */
  font-family: var(--app-primary-font-style);
  font-size: var(--app-large-font-size);
  font-style: normal;
  font-weight: var(--app-semi-bold-font-weight);
  line-height: 24px;
  /* 150% */
  margin: 10px 0px 0px 0px;
  padding: 0px;
}

.questionHeaderCountText {
  color: var(--app-gray-600-color);
  font-family: var(--app-primary-font-style);
  font-size: var(--app-small-font-size);
  font-style: normal;
  font-weight: var(--app-regular-font-weight);
  line-height: 20px;
  /* 166.667% */
  margin: 5px 0px 0px 0px;
  padding: 0px;
}

.questionHeaderSecondText {
  color: var(--app-gray-600-color);
  /* Text sm/Medium */
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-style: normal;
  font-weight: var(--app-semi-bold-font-weight);
  line-height: 20px;
  /* 142.857% */
  margin: 10px 0px 20px 0px;
  padding: 0px;
}

.introducationRightText {
  color: var(--app-gray-700-color);
  /* Text sm/Medium */
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-style: normal;
  font-weight: var(--app-semi-bold-font-weight);
  line-height: 20px;
  /* 142.857% */
}

.introducationRightMainDiv {
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 10px;
}

.interviewDescriptionLeft {
  height: 300px;
  padding-top: 60px;
}

.introducationRightSecondDiv {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.questionDetaileSeconddHeader {
  display: flex;
  justify-content: center;
}

.answerRules {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.parentContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  align-items: center;
  top: 0;
  padding: 2rem;
}

.recordIconDiv {
  display: flex;
  justify-content: center;
}

.hidePauseButton::-webkit-media-controls-play-button,
.hidePauseButton::-webkit-media-controls-pause-button {
  display: none !important;
}

.hidePauseButton::-moz-media-controls-play-button,
.hidePauseButton::-moz-media-controls-pause-button {
  display: none !important;
}

.hidePauseButton::--media-controls-play-button,
.hidePauseButton::--media-controls-pause-button {
  display: none !important;
}

.recordIconText {
  margin-left: 5px; /* Adjust as needed */
  color: var(--app-base-white-color);
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-weight: var(--app-bold-font-weight);
  line-height: 20px;
}

.countDownText {
  color: var(--app-base-white-color);
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-weight: var(--app-bold-font-weight);
  line-height: 20px;
}

.thankPageDiv {
  display: flex;
  justify-content: center;
}

.thankPageSecondDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
}

.thankPageText {
  color: var(--app-gray-900-color);
  text-align: center;

  /* Text md/Medium */
  font-family: var(--app-primary-font-style);
  font-size: var(--app-large-font-size);
  font-style: normal;
  font-weight: var(--app-semi-bold-font-weight);
  line-height: 24px;
  /* 150% */
}

.thankPageSecondText {
  color: var(--app-gray-600-color);
  text-align: center;

  /* Text sm/Medium */
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-style: normal;
  font-weight: var(--app-semi-bold-font-weight);
  line-height: 20px;
  /* 142.857% */
}

.waitingText {
  display: flex;
  flex-direction: row;
  justify-content: end;
  text-align: end;
  width: 93;
}

.uploadingWaitingText {
  text-align: right;
  font-family: var(--app-primary-font-style);
  font-size: var(--app-small-font-size);
  font-style: normal;
  font-weight: var(--app-semi-bold-font-weight);
  color: var(--app-success-500-color);
  line-height: 20px;
  padding-right: 4.5rem;
  /* 142.857% */
}
.buttonMainDiv{
  width: 93.5%;
}

.buttonSecondDiv{
  width: 98.5%;
  margin-top: 10px;
}
@media only screen and (max-width: 1400px){
  .uploadingWaitingText {
    padding-right: 3.5rem;
  }
}
@media only screen and (max-width: 1200px){
  .uploadingWaitingText {
    text-align:center;
    padding-right: 0;
  }
}


@media only screen and (max-width: 600px) {
  .answerRules {
    display: flex;
    flex-direction: column;
  }
  .buttonMainDiv{
    width: auto;
  }
  .buttonSecondDiv{
    width: auto;
    margin-top: 10px;
  }
  .mainQuestionSecondDetailed {
    border-radius: 12px;
    border: 1px solid var(--app-gray-200-color);
    background: var(--app-base-white-color);
    /* Shadow/xs */
    padding: 0px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .questionDetailedHeader {
    display: flex;
    flex: auto;
    flex-direction: row;
    justify-content: space-between;
  }

  .mainRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    height: 400px;
  }

  .interviewDescription {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 400px;
    border-radius: 12px;
    background: var(--app-base-black-color);
    padding-left: 0px;
    margin-left: 0px;
  }

  .interviewDescription video {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }

  .interviewDescriptionLeft {
    height: 400px;
    padding-top: 100px;
  }
}