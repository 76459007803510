.mainColumnBody {
  width: 100%;
  min-height: 100vh;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.mainColumnBody::before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40%;
  min-height: 100vh;
  left: 0;
  background-color: var(--app-primary-700-color);
}

.mainColumnBodyInterview {
  width: 100%;
  min-height: 100vh;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.mainColumnBodyInterview::before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40%;
  min-height: 100vh;
  left: 0;
}

// .fullscreenContainer {
//   width: -webkit-fill-available;
//   height: -webkit-fill-available;
// }

.fullscreenContainer {
  display: flex;
  flex-direction: column; /* or row, depending on your layout */
  justify-content: center; /* vertically center content */
  align-items: center; /* horizontally center content */
  width: 100%;
  height: 100%;
}

.bodyColumn {
  margin-top: 3%;
}

.bodyRow {
  padding-top: 8%;
}
.interviewDetailsSide{
  border: none !important;
}
.interviewDetailsSidebar{
  margin-top: 3rem;
  padding-left: 2rem;
  min-height: 80vh;
}
.companyLogo {
  margin-top: 5%;
  margin-bottom: 5%;
  max-width: 100%;
  max-height: 248px;
}

.companyLogoImage{
  max-width: 100%;
  max-height: 248px;
  margin-bottom: 2em;
}

.backgroundLeftLine {
  content: "";
  position: absolute;
  min-height: 100vh;
  background-color: var(--app-primary-700-color);
}

.cardTitle {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: 28px;
  text-align: center;
}

.cardBodyText {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-semi-bold-font-weight);
  font-size: var(--app-large-font-size);
  text-align: center;
  margin-top: 5%;
}

.settingDiv {
  margin-bottom: 5%;
}

.configurationsTitle {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-extra-large-font-size);
  text-align: center;
  margin-bottom: 2%;
  color: var(--app-gray-50-color);
}

.videoConfigurationsTitle {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-extra-large-font-size);
  text-align: center;
  margin-bottom: 5%;
  color: var(--app-gray-50-color);
  margin-top: 2em;
}

.previewVideoBody {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
  width: 100%;
  background: var(--app-gray-900-color);
  position: relative;
  padding: 1rem;
}

.vidioPrivewRelativeDiv {
  position: relative;
  width: 30rem;
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: var(--app-gray-900-color); 
  border-radius: 8px; 
  margin-bottom: 1rem; 
}
.noPreviewViedo{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--app-gray-400-color);
}

.absoluteControls {
  position: absolute;
  bottom: 1rem; 
  left: 50%; 
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem; 
  background-color: rgba(0, 0, 0, 0.5); 
  padding: 0.5rem; 
  border-radius: 4px;
}

.oneRemoteVideoScreenSize {
  width: 100%;
  background: var(--app-base-white-color);
  margin-bottom: 0px;
  cursor: pointer;
  padding: 2px;
  background: none;

  &:hover {
    scale: 1.02;
  }
}

.interviewDetailsView {
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin: 0 !important;
  padding: 0 !important;
  // margin-bottom: 5%;
}

.remoteVideosCol {
  padding-left: 0px !important;
  padding-right: 0px !important;
  overflow-y: auto;
  overflow-x: hidden !important; 
  background: var(--app-gray-800-color);
  height: 80vh;
  margin: 0.5rem;
  border-radius: 10px;
  padding-top: 1rem;
  
}

/* Custom scrollbar styles */
.remoteVideosCol::-webkit-scrollbar {
  width: 8px; 
}

.remoteVideosCol::-webkit-scrollbar-track {
  background: var(--app-gray-900-color); 
  border-radius: 10px;
}

.remoteVideosCol::-webkit-scrollbar-thumb {
  background-color: var(--app-gray-500-color); 
  border-radius: 10px;
  border: 3px solid var(--app-gray-800-color);
}

.remoteVideosCol::-webkit-scrollbar-thumb:hover {
  background-color: var(--app-gray-400-color); 
}


.remoteVideosInnerCol {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.remoteVideosInnerCol2 {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
.mainVideoBackgroundCol {
  padding-left: 0px !important;
  padding-right: 0.1px;
}

.mainVideoBackground {
  padding-right: 0px !important;
  padding-left: 0px !important;
  background: rgb(27, 28, 32);
  align-items: center;
  justify-content: center;
  display: flex;
  height: 80vh;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.mainVideoBackgroundCard {
  border: none !important;
  background-color: var(--app-gray-700-color);
}

.cardNoPreview {
  background: var(--app-base-black-color);
  width: 100%;
}

.tileOuterBox{
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.tilesMessageBox{
  width: 100%;
  margin-bottom: -15px;
  padding: 0 2px;
}

.cardBodyNoPreview {
  padding: 1rem !important;
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 100px;
}

.hidevideoTile {
  display: none !important;
}

.endMeetingBtn {
  width: fit-content !important;
  background-color: var(--app-error-600-color) !important;
  border: none !important;
  color: var(--app-base-white-color) !important;
  box-shadow: none !important;

  &:hover {
    background-color: var(--app-error-500-color) !important;
  }
}

.actionBtnRow {
  align-items: center;
  padding-top: 2%;
  padding-bottom: 2%;
  background-color: var(--app-gray-700-color);
}

.actionBtnInnerRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &div{
    width: fit-content !important;
  }
  &span{
    width: fit-content !important;
  }
  
}
.rosterBtn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rosterBtnText{
  margin-top: 3px;
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-semi-bold-font-weight);
  font-size: var(--app-extra-small-font-size);
  margin-bottom: 5%;
  color: var(--app-gray-25-color);
}
.selectedBtn{
  svg{
    path{
      stroke: var(--app-base-black-color);
    }
  }
}
.unSelectedBtn:hover {
  svg{
    path{
      stroke: var(--app-base-black-color);
    }
  }
}


.attendeesNoBadge {
  background: var(--app-error-500-color) !important;
  font-size: 0.65rem !important;
}

.videoTileBtn {
  margin-left: 15px;
  width: fit-content !important;
}

.videoEnlargeBtn {
  width: fit-content !important;
}

.rosterColumn {
  align-self: center;
  text-align: center;
}

.buttonRowOutline {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonOutline {
  display: flex;
  align-items: center;
  justify-content: center;
  gap:1rem;
}

.buttonRowOutlineHover {
  position: absolute;
  background-color: var(--app-gray-800-color);
  border-radius: 50px;
  box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.5);
  bottom: 10%;
  left: 32%;
  transform: translate(0%, 50%);
  opacity: 0;
  transition: ease-in-out 0.5s;
}

.buttonRowOutlineHover:hover {
  opacity: 1;
}

.showcontrols {
  opacity: 1;
}

.controlInputIcon{
  width: fit-content !important;
}

.candidateInterviewBody {
  padding-top: 0.4rem;
  min-height: 100vh;
}
.candidateInterviewBody1 {
  margin-top: 0.5rem;
  padding-top: 0.4rem !important;
  padding-bottom: 0 !important;
  background-color: var(--app-gray-700-color);
  min-height: 80vh;
}

.mainScreenText {
  font-family: var(--app-primary-font-style);
  font-size: var(--app-semi-small-font-size);
  font-weight: var(--app-bold-font-weight);
  color: var(--app-base-white-color);
  text-align: center;
}

.alertColumn {
  margin-left: -29px !important;
  padding-right: 0px !important;
  margin-right: 10px;
  margin-bottom: 5px;
}

.infoAlert {
  margin-right: -29px;
  margin-bottom: 0px !important;
  padding: 5px 20px !important;
}

.mainLoader {
  margin-top: 20%;
}

.mainerror {
  margin-top: 15%;
}

.bottomImageCloumn {
  margin-top: 4%;
}

.btnRowSettings {
  margin-top: 5%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 2rem;
}

.joinMeetingCard {
  padding-top: 20px;
  padding-bottom: 20px;
}

.cardBodySettings {
  margin-top: 2%;
}

.stepDescription {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-semi-bold-font-weight);
  font-size: var(--app-xx-extra-large-font-size);
  text-align: center;
  width: 100%;
  color: var(--app-primary-600-color);
  margin-bottom: 3em;
}

.bottomRightSvg {
  position: absolute;
  bottom: 0;
  right: 0;
}

.interviewDetailssTopic {
  font-family: var(--app-primary-font-style);
  font-size: var(--app-xx-extra-large-font-size);
  font-weight: var(--app-bold-font-weight);
  color: var(--app-gray-900-color);
  //text-align: center;
}

.interviewDetailsSubTopic {
  font-family: var(--app-primary-font-style);
  font-size: var(--app-x-extra-large-font-size);
  font-weight: var(--app-bold-font-weight);
  text-align: left;
  margin-top: 8%;
  color: var(--app-primary-600-color);
  margin-bottom: 0.5em;
}

.mainBodyCard {
  margin-right: 0.25rem;
  background-color: var(--app-base-white-color);
  border: none !important;
  box-shadow: none !important;
}

.topicLabels {
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  font-weight: var(--app-bold-font-weight);
  opacity: 0.9;
  color: var(--app-gray-900-color);
  text-align: left;
  margin-bottom: 5px !important;
  width: auto;
}

.topicLabelDiv{
  width: 22%;
}

.secondTopicLabelDiv{
  width: fit-content;
  padding-left: 0px;
}
.topicValues {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-semi-bold-font-weight);
  font-size: var(--app-primary-font-size);
  color: var(--app-gray-900-color);
  opacity: 0.8;
  text-align: left;
  margin-bottom: 5px !important;
  //justify-content: baseline;
}

.confirgureDeviceSettingsBox{
  margin-top: 15vh;
  background-color: var(--app-gray-900-color);
}

.interviewBodyErrorMsgBox{
  margin-top: 43vh;
  margin-left: 24vw;
  background-color: var(--app-error-700-color);
  color: var(--app-base-white-color);
  font-family: var(--app-primary-font-style);
  font-size: var(--app-large-font-size);
  font-weight: var(--app-semi-bold-font-weight);
  height: 100%;
  padding: 5px 30px;
}

.interviewBodyErrorMsgText{
  margin-bottom: 0;
}

.cardBodyDataDiv {
  padding-left: 50px;
}

.cardBodyDepartmentDataDiv {
  padding-left: 20px;
}

.chatContainer {
  height: 65vh;
  overflow-y: auto;
  margin: 0;
  padding: 0;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color:var(--app-primary-700-color);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--app-primary-700-color);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--app-gray-600-color);
  }
}

.attendeesbox{
  margin: 0 0rem !important
  
}


.textBoxContainer {
  margin-top: auto;
  padding: 0.5rem;
}

.descColumn {
  align-self: center;
}

.responseTitle {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-xx-extra-large-font-size);
  color: var(--app-success-600-color);
  text-align: center;
}

.responseSuggestion {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-semi-bold-font-weight);
  font-size: var(--app-extra-large-font-size);
  opacity: 0.7;
  text-align: center;
}

.cardBodylabelsRes {
  text-align: center;
  margin-bottom: 5px !important;
  background-color: rgb(108, 144, 243);
  border-radius: 50px;
  color: var(--app-base-white-color);
  padding-top: 3.5px;
  padding-bottom: 3.5px;
  padding-left: 3px;
  padding-right: 3px;
}

.labelTopic {
  font-family: var(--app-primary-font-style);
  font-size: var(--app-large-font-size);
  font-weight: var(--app-bold-font-weight);
}

.labelValues {
  font-family: var(--app-primary-font-style);
  font-size: 15px;
  font-weight: var(--app-regular-font-weight);
  margin-left: 2%;
  opacity: 0.85;
}


//Overlay Loading

.spanner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 0;
  background: var(--app-primary-700-color);
  width: 100%;
  display: block;
  text-align: center;
  height: 100%;
  color: var(--app-base-white-color);
  transform: translateY(-50%);
  z-index: 1000;
  visibility: hidden;

  p {
    font-size: var(--app-xx-extra-large-font-size);
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  overflow: hidden;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: var(--app-base-white-color);
  font-size: var(--app-large-font-size);
  margin: 350px auto -50px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

.loaderText {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-xxx-extra-large-font-size);
}


.footerCreditBox {
  width: 100%;
  font-size: var(--app-small-font-size);
  color: var(--app-gray-500-color);
  font-family: var(--app-primary-font-style);
  text-align: center;
  padding: 0 2rem;
}
.footerCreditBoxInner{
  padding:0.25rem;
}
.footerCreditLink {
  color: var(--app-primary-600-color);
  text-decoration: none;
  font-size: var(--app-semi-small-font-size);
}

.footerCreditLink:hover {
  color: var(--app-primary-700-color);
}
.contentShareDiv{
  width: fit-content !important;
}
.videoTiles{
  width: 100%;
  height: 170px;
}
.relativeDiv {
  position: relative;
  width: 100%;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px; 
  overflow: hidden;
  margin-bottom: 0.5rem;
  background-color: var(--app-gray-900-color);
}

.absoluteDiv {
  scale: 0.8;
  position: absolute;
  bottom: -0.5rem; 
  width: 130%;
  background-color: rgba(0, 0, 0, 0.4);
  color: var(--app-base-white-color);
  border-radius: 4px; 
  align-items: center;
  padding-top: 0;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  .gsODlO{
    padding-top: 0 !important;
  }
}

.nameLogo {
  width: 70px; 
  height: 70px; 
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var( --app-primary-600-color);
  color: var(--app-base-white-color);
  border-radius: 50%; 
  font-size: var(--app-xxx-extra-large-font-size); 
  font-weight: var(--app-bold-font-weight);
  text-transform: uppercase;
  margin-bottom: 2.5rem;
}
.titleTxt{
  color: var(--app-base-white-color);
  font-size: var(--app-semi-small-font-size);
  display: flex;
  align-items: center;
}

.sendMsgDiv{
  padding-top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: auto;
  min-height: 30px;
}
.messageArea {
  flex: 1;
  padding: 3px 5px 3px 5px;
  font-size: var(--app-semi-small-font-size);
  width: 100%;
  height: fit-content;
  min-height: 20px;
  max-height: 200px;
  overflow-y: auto;
  background: var(--app-gray-600-color);
  border: 1px solid var(--app-gray-900-color);
  border-radius: 6px;
  color: var(--app-base-white-color);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color:var(--app-primary-700-color);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--app-primary-700-color);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--app-gray-600-color);
  }
}

.sendButton {
  margin-left: 0.5rem;
  background: none;     
  border: none;        
  padding: 0;           
  cursor: pointer;      
  display: inline-flex; 
  align-items: center; 
  justify-content: center; 
}

.sendButton svg { 
  path{
    stroke: var(--app-primary-500-color) ;
  }    
  transition: fill 0.3s; 
}

.sendButton:hover svg {
  path{
    stroke: var(--app-primary-600-color) ;
  } 
}

.errorMsg {
  font-family: var(--app-primary-font-style);
  font-size: var(--app-small-font-size);
  font-weight: var(--app-regular-font-weight);
  color: var(--app-error-700-color);
  margin-top: 0;
  margin-bottom: 0.1rem;
}

.connctionIssueModal{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  padding: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color:var(--app-base-white-color);
  height: 100%;
  position:absolute;
  z-index: 1000;
  width: 100%;

}
.innerConnctionIssueModal{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.connctionIssueTitle10{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:0.5rem;
  font-size: var(--app-extra-large-font-size);
  font-weight: var(--app-bold-font-weight);
  color: var(--app-primary-400-color);

  svg{
    path{
      stroke: var(--app-primary-400-color);
    }
  }
}
.connctionIssueTitle20{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:0.5rem;
  font-size: var(--app-extra-large-font-size);
  font-weight: var(--app-bold-font-weight);
  color: var(--app-error-500-color);

  svg{
    path{
      stroke: var(--app-error-500-color);
    }
  }
}
.connctionIssueText{
  font-size: var(--app-primary-font-size);
  text-align: center;
}
.connectionLoader {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side,#ffffff 90%,#f5eded00);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}



@media only screen and (max-width: 600px) {
  .mainBodyCard {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    background-color: var(--app-gray-900-color);
    border: none !important;
    box-shadow: none !important;
    border-radius: 0;
  }
  
  .candidateInterviewBody {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    min-height: 100vh;
    border-radius: 0;
    width: 100%;
  }
  .nameLogo{
    margin-bottom: 0;
  }
  .remoteVideosCol{
    margin-top: 0;
    margin-bottom: 0;
  }
  .candidateInterviewBody1 {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0 !important;
    min-height: 30vh;
    width: 100%;
    border-radius: 0;
  }
  .mainVideoBackground {
    padding-right: 0px !important;
    padding-left: 0px !important;
    background: rgb(27, 28, 32);
    align-items: center;
    justify-content: center;
    display: flex;
    height: 500px;
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0;
    margin-left: 0;
  }
  .fullscreenContainer {
    margin-left: 2.5%;
    display: flex;
    flex-direction: column; /* or row, depending on your layout */
    justify-content: center; /* vertically center content */
    align-items: center; /* horizontally center content */
    width: 90%;
    height: 500px;
  }
  .titleTxt{
    display: none !important;
  }
  .remoteVideosInnerCol {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .tileOuterBox{
    display: flex;
    flex-direction: column;
  }
  .companyLogo {
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .orderfirst {
    order: -1 !important; /* Use !important to ensure the order is overridden */
    padding-left: 0.1%;
  }

  .orderfirstMain {
    order: -1 !important; /* Use !important to ensure the order is overridden */
    padding-left: 3%;
  }

  .topicLabelDiv{
    width: 30%;
    margin-left: 1rem;
  }

  .mainScreenText {
    margin-top: 50%;
  }

  .oneRemoteVideoScreenSize {
    height: 80px;
  }
  .previewVideoBody {
    width: 80%;
    margin-left: 10%;
    background: var(--app-gray-900-color);
  }
  .remoteVideosCol {
    flex-direction: column;
    align-items: center;
    height: auto !important;
  }
  .remoteVideosInnerCol2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .oneRemoteVideoScreenSize {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0.25rem;
  }


  .chatContainer {
    height: 300px;
  }

  .textBoxContainer {
    padding: 0.5rem;
  }

  .sendMsgDiv {
    display: flex;
    flex-direction: row;
  }

  .messageArea {
    width: 200px;
  }

  .sendButton {
    text-align: center;
  }
  .buttonRowOutlineHover {
    position: absolute;
    background-color: var(--app-base-white-color);
    border-radius: 50px;
    box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.5);
    bottom: 10%;
    transform: translate(0%, 50%);
    opacity: 0;
    transition: ease-in-out 0.5s;
    left: 8%;
  }

  .contentShareDiv{
    display: none;
  }
  .confirgureDeviceSettingsBox{
    margin-top: 4vh;
    margin-bottom: 4vh;
  }

  .interviewDetailsSidebar{
    margin-top: 1rem;
    margin-left: 1rem;
    min-height: 80vh;
  }
  .interviewDataPart{
    flex-direction: column-reverse;
  }
  .relativeDiv{
    height: 120px;
  }
  .videoTileBtn{
    display: none;
  }

  .vidioPrivewRelativeDiv{
    width: 15rem;
    height: 9rem;
  }
 
}

/* Styles for screens between 600px and 1024px (tablet view) */
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .candidateInterviewBody1{
    width: 100%;
  }
  .interviewDetailsView{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--app-base-white-color);
    width: 100%;  
  }
  .mainBodyCard {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    background-color: var(--app-gray-900-color);
    border: none !important;
    box-shadow: none !important;
    border-radius: 0;
  }
  
  .candidateInterviewBody {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    min-height: 100vh;
    border-radius: 0;
  }
  .rowFlex{
    display: flex;
    flex-direction: column;
  }
  .candidateInterviewBody1 {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    min-height: 30vh;
    border-radius: 0;
  }
  .interviewDataPart{
    flex-direction: column-reverse;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mainVideoBackground {
    padding-right: 0px !important;
    padding-left: 0px !important;
    background: rgb(27, 28, 32);
    align-items: center;
    justify-content: center;
    display: flex;
    height: 450px;
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0;
    margin-left: 0;
  }
  .mainVideoRow{
    flex-direction: column;
  }
  .interviewDetailsSide{
    display: flex;
    width: 60%;
  }
  .fullscreenContainer {
    display: flex;
    flex-direction: column; /* or row, depending on your layout */
    justify-content: center; /* vertically center content */
    align-items: center; /* horizontally center content */
    width: 100%;
    height: 450px;
  }
  .titleTxt{
    display: none !important;
  }
  .remoteVideosInnerCol {
    width: 100%;
    padding-left: 25% !important;
    padding-right: 25% !important;
  }
  .tileOuterBox{
    display: flex;
    flex-direction: column;
  }
  .companyLogo {
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .orderfirst {
    order: -1 !important; /* Use !important to ensure the order is overridden */
    padding: 0;
  }

  .orderfirstMain {
    order: -1 !important; /* Use !important to ensure the order is overridden */
    padding-left: 3%;
  }

  .topicLabelDiv{
    width: 30%;
    margin-left: 1rem;
  }

  .mainScreenText {
    margin-top: 50%;
  }

  .oneRemoteVideoScreenSize {
    height: 80px;
  }
  .previewVideoBody {
    width: 80%;
    margin-left: 10%;
    background: var(--app-gray-900-color);
  }
  .remoteVideosCol {
    margin: 0;
    flex-direction: column;
    width:100%;
    align-items: center;
    min-height: 300px;
    height: auto !important;
    
  }
  .actionBtnRow{
    justify-content: center;
  }
  .relativeDiv{
    height: 150px;
  }
  .remoteVideosInnerCol2 {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }

  .oneRemoteVideoScreenSize {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0.25rem;
  }


  .chatContainer {
    height: 300px;
  }

  .textBoxContainer {
    padding: 0.5rem;
  }

  .sendMsgDiv {
    display: flex;
    flex-direction: row;
  }

  .messageArea {
    width: 200px;
  }

  .sendButton {
    text-align: center;
  }
  .buttonRowOutlineHover {
    position: absolute;
    background-color: var(--app-base-white-color);
    border-radius: 50px;
    box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.5);
    bottom: 10%;
    transform: translate(0%, 50%);
    opacity: 0;
    transition: ease-in-out 0.5s;
    left: 8%;
  }

  .contentShareDiv{
    display: none;
  }
  .confirgureDeviceSettingsBox{
    margin-top: 4vh;
    margin-bottom: 4vh;
  }

  .interviewDetailsSidebar{
    margin-top: 1rem;
    margin-left: 1rem;
    min-height: 80vh;
  }
  .companyLogo{
    display: flex;
    justify-content: center;
  }
  .companyLogoImage{
    display: flex;
    justify-content: center;
  }
  .rowFlex{
    width: 100%;
  }
  .candidateInterviewBody{
    width: 100%;
  }
  .candidateInterviewBody1{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .mainVideoBackgroundCol {
    padding-left: 0px !important;
    padding-right: 0px !important;
  
  }
  .innterCnadidateInterviewBody{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .interviewDataPartCol{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .vidioPrivewRelativeDiv{
    width: 20rem;
    height: 11rem;
  }
  
}

@media only screen and (min-width: 1024px) and (max-width: 1400px) {
  .cardBodySettings {
    margin: 0;
    padding-left: 2%;
  }
  .vidioPrivewRelativeDiv{
    width: 18rem;
    height: 11.5rem;
  }
  .userDetailsDataRef{
    margin-left: 0;
  }

}
@media only screen and (min-width: 1400px) and (max-width: 1800px) {

  .vidioPrivewRelativeDiv{
    width: 19rem;
    height: 12.5rem;
  }

}

@media only screen and (max-width: 767px){
  .footerCreditBox{
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}

