
.dropzone {  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 6px;
  border-color: var(--app-gray-300-color);
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  &:hover {
    border-color: var(--app-primary-700-color);
  }
  &:focus {
    border-color: var(--app-primary-700-color);
  }
}

.dropzoneInvalid {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 1px;
  border-radius: 6px;
  border-color: #dc3545;
  border-style: solid;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;

  &:hover {
    border-color: #dc3545;
  }
  &:focus {
    border-color: #dc3545;
  }
}

.fileSizeLabel {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-regular-font-weight);
  font-size: var(--app-semi-small-font-size);
  color: var(--app-base-black-color);
}

.errormessage {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--app-error-700-color);
  margin-bottom: 0px;
}

.defaultLabel {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-primary-font-size);
  color: var(--app-base-black-color);
  margin-top:0;
  margin-top:0.35rem;

  span {
    font-weight: var(--app-regular-font-weight);
    font-size: var(--app-semi-small-font-size);
  }
}

.disabledLabel{
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-primary-font-size);
  color: var(--app-gray-300-color);
  margin-top: 0;
  margin-bottom: 0.35rem
}


.filetopic {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-large-font-size);
  margin-bottom: 0px;
}

.fileNameLabel {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-primary-font-size);
}

.fileNameLabelSuccess{
 font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-primary-font-size);
  color: #269625;
}

.errorLabel {
  font-family: var(--app-primary-font-style);
  font-size: var(--app-small-font-size);
  font-weight: var(--app-regular-font-weight);
  color: var(--app-error-700-color);
  margin-top: 0;
  margin-bottom: 0.1rem;
}

.image_container {
  width: 150px;
  height: 150px;
  overflow: hidden !important;
}
