@font-face {
  font-family: 'Helvetica';
  src: local('Helvetica'),
    url(/fonts/Helvetica/Helvetica.ttf) format('truetype'),
    url(/fonts/Helvetica/Helvetica-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Nexa';
    src: url('../fonts/Nexa/nexathin-webfont.woff2') format('woff2'),
         url('../fonts/Nexa/nexathin-webfont.woff') format('woff');
    font-weight: 250;
    font-style: normal;

}

@font-face {
    font-family: 'Nexa';
    src: url('../fonts/Nexa/nexalight-webfont.woff2') format('woff2'),
         url('../fonts/Nexa/nexalight-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}

@font-face {
    font-family: 'Nexa';
    src: url('../fonts/Nexa/nexabook-webfont.woff2') format('woff2'),
         url('../fonts/Nexa/nexabook-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'Nexa';
    src: url('../fonts/Nexa/nexaregular-webfont.woff2') format('woff2'),
         url('../fonts/Nexa/nexaregular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'nexabold';
    src: url('../fonts/Nexa/nexabold-webfont.woff2') format('woff2'),
         url('../fonts/Nexa/nexabold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'Nexa';
    src: url('../fonts/Nexa/nexaxbold-webfont.woff2') format('woff2'),
         url('../fonts/Nexa/nexaxbold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'Nexa';
    src: url('../fonts/Nexa/nexablack-webfont.woff2') format('woff2'),
         url('../fonts/Nexa/nexablack-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;

}

@font-face {
    font-family: 'Nexa';
    src: url('../fonts/Nexa/nexaheavy-webfont.woff2') format('woff2'),
         url('../fonts/Nexa/nexaheavy-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;

}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap');

//OLD FONTS ends +=====================

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');

:root{

      // Fonts
  --app-primary-font-style: "Inter";


  // Font Sizes Desktop
  --app-primary-font-size:14px;
  --app-semi-small-font-size:13px;
  --app-small-font-size:12px;
  --app-extra-small-font-size:10px;
  --app-large-font-size:16px;
  --app-extra-large-font-size:18px;
  --app-x-extra-large-font-size:20px;
  --app-xx-extra-large-font-size:24px;
  --app-xxx-extra-large-font-size:32px;
  --app-xxxx-extra-large-font-size:48px;
  --app-xx-extra-large-second-font-size:28px;
  // font weights
  --app-thin-font-weight: 300;
  --app-regular-font-weight: 400;
  --app-semi-bold-font-weight: 500;
  --app-bold-font-weight: 600;

  // ------------------------------------------old fonts-----------------------------
   
    // Fonts
    --app-main-font:'Helvetica'; 
    --app-secondary-font:'Roboto';  
    --app-dashbaord-card-body-font:'Merriweather'; 
    --app-dashboard-subheading-font:'Montserrat'; 
    --app-dashbaord-body-font:'Open Sans'; 

    // Font weights
    --app-main-thin-font-weight:100; 
    --app-main-light-font-weight:300; 
    --app-main-regular-font-weight: 400; 
    --app-medium-font-weight: 500; 
    --app-semi-bold-weight:600;  
    --app-main-bold-font-weight: 700; 
    --app-main-extra-bold-font-weight: 900; 

    // Font Sizes Desktop
    --app-main-extra-small-font-size:11px; 
    --app-main-small-font-size:12px; 
    --app-main-regular-v2-font-size:15px; 
    --app-default-font-size:13px; 
    --app-main-regular-font-size:14px;  
    --app-main-regular-secondary-font-size:16px; 
    --app-medium-primary-font-size:18px;  
    --app-medium-secondary-font-size:20px;  
    --app-main-sub-title-font-size:24px;  
    --app-primary-sub-title-font-size: 26px;  
    --app-main-secondary-title-font-size:38px;
    --app-secondary-title-medium-font-size:33px; 
    --app-main-title-font-size:43px; 


    // Font Sizes Mobile
    --app-mb-extra-small-font-size:12pt; 
    --app-mb-small-font-size:14pt; 
    --app-mb-default-font-size:16pt; 
    --app-mb-sub-title-font-size:28pt; 
    --app-mb-secondary-title-font-size:38pt; 
    --app-mb-title-font-size:48pt; 


    // =============================================================== New UI Fonts===============================================================


    //New UI Fonts
    --app-primary-font:'Nexa'; 


    //New UI Fonts Weights
    --app-thin-font-weight:250; 
    --app-light-font-weight:300;  
    --app-book-font-weight:400; 
    --app-regular-weight:400; 
    --app-bold-font-weight:600;  
    --app-extra-bold-font-weight:700;  
    --app-black-font-weight:800; 
    --app-heavy-font-weight:800; 

    //New UI Fonts Sizes Desktop
    --app-extra-small-font-size:12px;
    --app-small-font-size:13px; 
    --app-regular-font-size:14px; 
    --app-regular-secondary-font-size:16px; 
    --app-sub-title-font-size:24px; 
    --app-title-font-size:28px;  
    --app-secondary-title-font-size:36px; 

    
}