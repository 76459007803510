.defaultLabel {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-primary-font-size);
  color: var(--app-base-black-color);
  margin-top: 0;
  margin-bottom: 0.35rem
}

.disabledLabel{
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-primary-font-size);
  color: var(--app-gray-300-color);
  margin-top: 0;
  margin-bottom: 0.35rem
}

.classLabelDesc{
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-regular-font-weight);
  font-size: var(--app-semi-small-font-size);
  color: var(--app-gray-600-color);
}

//Default Dropdown
.defaultDropDown {
  border-radius: 6px !important;
  border: 1px solid var(--app-gray-300-color);
  box-shadow: none !important;
  outline: none !important;
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-regular-font-weight);
  font-size: var(--app-semi-small-font-size);
  color: var(--app-base-black-color);

  &:hover {
    border: 1px solid var(--app-primary-700-color);
  }
  &:focus {
    border: 1px solid var(--app-primary-700-color);
  }
  &:active {
    border: 1px solid var(--app-primary-700-color);
  }
}

.disabledDropDown{
  border-radius: 6px !important;
  border: 1px solid var(--app-gray-300-color);
  box-shadow: none !important;
  outline: none !important;
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-regular-font-weight);
  font-size: var(--app-semi-small-font-size);
  color: var( --app-gray-300-color);

  &:hover {
    border: 1px solid var(--app-gray-300-color);
  }
  &:focus {
    border: 1px solid var(--app-gray-300-color);
  }
  &:active {
    border: 1px solid var(--app-gray-300-color);
  }
}

.errorMessage {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-regular-font-weight);
  font-size: var(--app-semi-small-font-size);
  color: var(--app-error-700-color);
  margin-top: 2%;
}

.smallDropDown {
  border-radius: 6px !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-regular-font-weight);
  font-size: var(--app-semi-small-font-size);
  color: var(--app-base-black-color);
}

.smallDropDownForm{
  margin-bottom: 0px;
}


.labelWithToolTip{
  margin-bottom: 0;
}
.toolTipBox{
  margin-bottom: -15px;
}