.defaultLabel {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-primary-font-size);
  color: var(--app-base-black-color);
  margin-top: 0;
  margin-bottom: 0.35rem
}

.disabledLabel{
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-primary-font-size);
  color: var(--app-gray-300-color);
  margin-top: 0;
  margin-bottom: 0.35rem
}

.defaultTextBox {
  border: 1px solid var(--app-gray-300-color);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 1.95rem;
  border-radius: 6px !important;
  box-shadow: none !important;
  outline: none !important;
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-regular-font-weight);
  font-size: var(--app-semi-small-font-size);
  color: var(--app-base-black-color);
  height: 36px;
  margin-bottom: 0.1rem;

  &:hover {
    border: 1px solid var(--app-primary-700-color) !important;
  }
  &:active {
    border: 1px solid var(--app-primary-700-color) !important;
  }
  &:focus {
    border: 1px solid var(--app-primary-700-color) !important;
  }
}

.savableAndCancelableTextBox {
  border: 1px solid var(--app-gray-300-color);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 1.95rem;
  border-radius: 6px !important;
  box-shadow: none !important;
  outline: none !important;
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-regular-font-weight);
  font-size: var(--app-semi-small-font-size);
  color: var(--app-base-black-color);
  height: 36px;
  margin-bottom: 0.1rem;
  background-image: none !important;

  &:hover {
    border: 1px solid var(--app-primary-700-color) !important;
  }
  &:active {
    border: 1px solid var(--app-primary-700-color) !important;
  }
  &:focus {
    border: 1px solid var(--app-primary-700-color) !important;
  }
}

.disabledTextBox {
  border: 1px solid var(--app-gray-300-color);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 1.95rem;
  border-radius: 6px !important;
  box-shadow: none !important;
  outline: none !important;
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-regular-font-weight);
  font-size: var(--app-semi-small-font-size);
  color: var(--app-gray-300-color);
  height: 36px;
  margin-bottom: 0.1rem;

  &:hover {
    border: 1px solid var(--app-gray-300-color) !important;
  }
  &:active {
    border: 1px solid var(--app-gray-300-color) !important;
  }
  &:focus {
    border: 1px solid var(--app-gray-300-color) !important;
  }
}

.cancelIconDiv {
  position: relative;
  float: right;
  top: -32px;
  right: 10px;
  cursor: pointer;
  &:hover {
    svg path {
      stroke: var(--app-primary-700-color);
    }
  }
}

.editIconDiv {
  position: relative;
  float: right;
  top: -32px;
  right: 10px;
  cursor: pointer;
  &:hover {
    svg path {
      stroke: var(--app-primary-700-color);
    }
  }
}

.loadingIcon {
  position: relative;
  float: right;
  top: -32px;
  right: -30px;
  cursor: pointer;
  i {
    color: #2da6c0;
  }
}

.saveIconDiv {
  position: relative;
  float: right;
  top: -32px;
  right: -50px;
  cursor: pointer;
  &:hover {
    svg path {
      stroke: var(--app-primary-700-color);
    }
  }
}

.deleteIconDiv {
  position: relative;
  float: right;
  top: -32px;
  right: -60px;
  cursor: pointer;
  &:hover {
    svg path {
      stroke: var(--app-warning-700-color);
    }
  }
}

.clearFieldIconDiv {
  position: relative;
  float: right;
  margin-left: 10px;
  top: 4px;
  cursor: pointer;
  &:hover {
    svg path {
      stroke: var(--app-warning-700-color);
    }
  }
}

.errorDiv {
  margin-bottom: 2%;
  margin-top: 1.5%;
}

.errorMsg {
  font-family: var(--app-primary-font-style);
  font-size: var(--app-small-font-size);
  font-weight: var(--app-regular-font-weight);
  color: var(--app-error-700-color);
  margin-top: 0;
  margin-bottom: 0.1rem;
}

.previewURL {
  word-break: break-word;
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-regular-font-weight);
  font-size: var(--app-primary-font-size);
  color: var(--app-gray-500-color);
}

.inputContainer{
  display: inline-flex;
}

.colorCodePreview{
  margin-right: auto;
  height: 34px;
  width: 30%;
}

.infoIconImage {
  width: 18px;
  height: 18px;
}

.editIconDivs {
  position: relative;
  float: right;
  top: -32px;
  left: 25px;
  // right: 20px;
  cursor: pointer;
  &:hover {
    svg path {
      stroke: var(--app-primary-700-color);
    }
  }
}

.defaultTextBoxColor {
  border: 1px solid var(--app-gray-300-color);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 1.95rem;
  border-radius: 6px !important;
  box-shadow: none !important;
  outline: none !important;
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-regular-font-weight);
  font-size: var(--app-semi-small-font-size);
  color: var(--app-base-black-color);
  height: 36px;
  position: relative;
  margin-left: 5%;
  // width: 100%;

  &:hover {
    border: 1px solid var(--app-primary-700-color) !important;
  }
  &:active {
    border: 1px solid var(--app-primary-700-color) !important;
  }
  &:focus {
    border: 1px solid var(--app-primary-700-color) !important;
  }
}

.labelIcon{
  display:inline-block;
  margin-bottom: -16px;
}

.labelWithToolTip{
  margin-bottom: 0;
}
.toolTipBox{
  margin-bottom: -15px;
}