// selected colors by user
$selected-primary-color: var(--selected-primary-color);

.bodyRow {
    margin-left: 0px !important;
    margin-right: 0px !important;
    height: 100vh;
  }
  .submissionFormView {
    padding: 2%;
  }

  .mainContainer {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .companySecondaryHeaderBox {
    display: none;
  }
  .pageTopic {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-xxx-extra-large-font-size);
    color: var(--app-base-black-color);
    text-align: center;
    margin-top: 20%;
  }
  .assessmentMain {
    color: var(--app-gray-600-color);
    text-align: center;
    font-family: var(--app-primary-font-style);
    font-size: var(--app-primary-font-size);
    font-style: normal;
    font-weight: var(--app-regular-font-weight);
    line-height: 20px;
    margin-bottom: 1.75rem;
  }
  .SecContainer{
    border: 1.5px solid var(--app-gray-200-color);
    border-radius: 1%;
    margin-top: 1rem;
  }
  .containerInnerWrap{
    margin: 1.5rem 0.5rem;
  }
  .submitFormTitle{
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    color: var(--app-gray-700-color);
    font-size: var(--app-primary-font-size);
    margin-left: 0px;
    margin-bottom: 0;
  }
  .submitFormSubTitle{
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-regular-font-weight);
    color: var(--app-gray-400-color);
    font-size: var(--app-small-font-size);
    margin-bottom: 0.5rem;
    margin-left: 0px;
  }
  .submitFormValues{
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-regular-font-weight);
    color: var(--app-gray-600-color);
    font-size: var(--app-primary-font-size);
    margin-bottom: 0;
    margin-left: 0px;
    margin-top:0;
    line-height: 16px;
  }
  .formDiatils{
    margin-bottom: 1.5rem;
  }

  //secondory

  .descriptionBox {
    background: $selected-primary-color;
    padding: 0.3% 0.5%;
}
  .descriptionInnerBox {
    border-radius: 10px;
    background: var(--app-base-white-color);
    height: 100%;
    padding: 0 5%;
    padding-top: 5%;
    padding-bottom: 20%;
  }
  .companyLogo {
    max-width: 100%;
    max-height: 248px;
    margin-bottom: 2rem;
  }
  .GuidelinesRow{
    flex-direction: row;
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    color: var(--app-gray-600-color);
    font-size: var(--app-large-font-size);
    margin-left: 0px;
}
.guidelineFistcol {
    margin: 0px;
    padding: 0px;
  }
  .nameTagValue {
    color: var(--app-gray-600-color);
    font-family: var(--app-primary-font-style);
    font-size: var(--app-primary-font-size);
    font-style: normal;
    font-weight: var(--app-bold-font-weight);
    line-height: 20px;
    padding: 0px;
  }
  .footerCreditBox {
    width: 100%;
    font-size: var(--app-small-font-size);
    color: var(--app-gray-500-color);
    font-family: var(--app-primary-font-style);
    text-align: center;
    padding: 0 2rem;
    margin-top: 30%;
  }
  .footerCreditBoxInner{
    padding:0.25rem;
  }
  
  //Overlay Loading

.spanner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 0;
    background: var(--app-primary-700-color);
    width: 100%;
    display: block;
    text-align: center;
    height: 100%;
    color: var(--app-base-white-color);
    transform: translateY(-50%);
    z-index: 1000;
    visibility: hidden;
  
    p {
      font-size: var(--app-xx-extra-large-font-size);
    }
  }
  
  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    overflow: hidden;
  }
  
  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 1em;
    height: 1em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }
  
  .loader {
    color: var(--app-base-white-color);
    font-size: var(--app-large-font-size);
    margin: 350px auto -50px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  
  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    top: 0;
  }
  
  .loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  
  .loader:after {
    left: 3.5em;
  }
  
  .loaderText {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-xxx-extra-large-font-size);
  }

  .guidlineTopicDiv{
    border-bottom: 1px solid var(--app-gray-200-color);
  }
  .pageGuidlineTopic {
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    font-size: var(--app-x-extra-large-font-size);
    color: var(--app-gray-700-color);
    text-align: left;
    margin-top: 1em;
    flex-direction: row;
    p{
        margin-bottom: 0.5rem;
    }
}
.guidlinecontentdiv{
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
  }
  
  .guidlinecontentMaindiv{
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
  
  .guidelinecontentCol{
    flex-direction: column;
      font-family: var(--app-primary-font-style);
      font-weight: var(--app-semi-bold-font-weight);
      color: var(--app-gray-600-color);
      font-size: var(--app-primary-font-size);
      margin-bottom: 0 !important;
  }
  .Guidelines{
    flex-direction: row;
    font-family: var(--app-primary-font-style);
    font-weight: var(--app-bold-font-weight);
    color: var(--app-gray-600-color);
    font-size: var(--app-primary-font-size);
    margin-bottom: 0 !important;
  }
  .footerCreditLink,  
  .guidelineLinks{
    color: var(--app-primary-600-color); 
    text-decoration: none;
  }

  @media only screen and (max-width: 767px){
    .footerCreditBox{
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }
  .mobileView{
    display:none;
  }
  @media only screen and (max-width: 1000px){
    .descktopView{
        display: none !important;
    } 
    .GuidelinesRow{
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      .guidelineFistcol {
        p{
          margin-bottom: 0;
        }
      }
    }
    .mobileView{
        display:block;
        padding-top: 1rem;
        padding-left: 0rem;
    }
    .mainContainer {
      padding-left: 0.1rem;
      padding-right: 0.1rem;
    }
  }

 