:root {

  //Text new add
  --app-badges-secondary-font-color: #27ae60;
  
  // Background
  --app-highlighted-background-color: #A5E5F3;
  
  //Background new added
  --app-secondary-background-color:#ECFAFD;

  //Border Colors
  --app-highlighted-border-color: #A5E5F3;
  
  
  //base Color
  --app-base-white-color: #FFFFFF;
  --app-base-black-color: #000000;

  //Gray Color
  --app-gray-25-color: #FCFCFD;
  --app-gray-50-color: #F9FAFB;
  --app-gray-100-color: #F2F4F7;
  --app-gray-200-color: #EAECF0;
  --app-gray-300-color: #D0D5DD;
  --app-gray-400-color: #98A2B3;
  --app-gray-500-color: #667085;
  --app-gray-600-color: #475467;
  --app-gray-700-color: #344054;
  --app-gray-800-color: #1D2939;
  --app-gray-900-color: #101828;

  //Primary Color
  --app-primary-25-color: #FAFEFF;
  --app-primary-50-color: #E1F6FB;
  --app-primary-100-color: #B4E8F4;
  --app-primary-200-color: #84DAED;
  --app-primary-300-color: #57CAE3;
  --app-primary-400-color: #3CBFDB;
  --app-primary-500-color: #32B5D3;
  --app-primary-600-color: #2DA6C0;
  --app-primary-700-color: #2790A6;
  --app-primary-800-color: #227D8D;
  --app-primary-900-color: #195A62;

  //Error Color
  --app-error-25-color: #FFFBFA;
  --app-error-50-color: #FEF3F2;
  --app-error-100-color: #FEE4E2;
  --app-error-200-color: #FECDCA;
  --app-error-300-color: #FDA29B;
  --app-error-400-color: #F97066;
  --app-error-500-color: #F04438;
  --app-error-600-color: #D92D20;
  --app-error-700-color: #B42318;
  --app-error-800-color: #912018;
  --app-error-900-color: #7A271A;

  //Warning Color
  --app-warning-25-color: #FFFCF5;
  --app-warning-50-color: #FFFAEB;
  --app-warning-100-color: #FEF0C7;
  --app-warning-200-color: #FEDF89;
  --app-warning-300-color: #FEC84B;
  --app-warning-400-color: #FDB022;
  --app-warning-500-color: #F79009;
  --app-warning-600-color: #DC6803;
  --app-warning-700-color: #B54708;
  --app-warning-800-color: #93370D;
  --app-warning-900-color: #7A2E0E;

  //Success Color
  --app-success-25-color: #F6FEF9;
  --app-success-50-color: #ECFDF3;
  --app-success-100-color: #D1FADF;
  --app-success-200-color: #A6F4C5;
  --app-success-300-color: #6CE9A6;
  --app-success-400-color: #32D583;
  --app-success-500-color: #12B76A;
  --app-success-600-color: #039855;
  --app-success-700-color: #027A48;
  --app-success-800-color: #05603A;
  --app-success-900-color: #054F31;

  //---------------------------------old colors---------------------------


  --app-main-primary-color: #2da6c0; 
  --app-secondary-color-one: #2d7ac0; 
  --app-secondary-color-two: #ffffff; 
  --app-secondary-color-three: #73a6d5; 

  // Background Colors
  --app-secondary-background-color: #137e78; 
  --app-action-background-color: #f2f2f2; 
  --app-light-background-color: #ffffff; 
  --app-dark-background-color: #333333; 


  // Font Colors
  --app-main-font-color: #000000; 
  --app-main-secondary-font-color: #ffffff; 
  --app-main-public-font-color: #4F4F4F; 
  --app-error-message-color: #ff0000; 
  --app-nav-link-color: #4a83c6; 
  --app-sidebar-link-color: #dfdfdf;  
  --app-sidebar-sub-topic-color: #e8e8e8; 
  --app-searchbar-placeholder-color: #2da6c0; 
  --app-dashboard-body-subtitle-color: #6c6b6b; 
  --app-step-count-color: #137e78; 
  --app-subtitle-color: #8d8d8d; 
  --app-select-box-default-text-color: #2e7658; 


  // Border Colors
  --app-primary-border-color: #2d7ac0; 
  --app-secondary-border-color: #d2d1d1; 
  --app-checkbox-border-color: #456462; 
 
  // Button Colors
  --app-primary-btn-background-color: #2da6c0; 
  --app-secondary-btn-color: #137e78; 
  --app-back-btn-color: #8d8d8d; 

  // =============================================================== New UI Colors===============================================================

  //==================Main Colors=================

  --app-primary-color: #2da6c0; 
  --app-secondary-color: #24859a; 
  --app-tertiary-color: #ffffff; 

  //==================Font Colors==================

  //Main
  --app-primary-font-color: #333333;  
  --app-secondary-font-color: #ffffff; 

  //Modal
  --app-placeholders-font-color: #bdbdbd; 
  --app-modal-title-font-color: #4f4f4f; 
  --app-modal-primary-font-color: #333333; 
  --app-modal-secondary-font-color: #4f4f4f; 
  --app-modal-disabled-font-color: #bdbdbd; 
  --app-modal-notice-font-color: #000000; 
  --app-modal-step-active-font-color: #ffffff; 

  //Alert Box
  --app-alert-box-primary-font-color: #4f4f4f; 
  --app-alert-box-secondary-font-color: #333333; 
  --app-alert-box-tertiary-font-color: #828282; 
  --app-alert-warning-box-default-font-color:#FFFFFF; 

  //Info Box
  --app-info-box-font-color: #24859a; 

  //Tables
  --app-table-titles-font-color: #0f50f9; 
  --app-table-data-font-color: #333333; 

  //Labels (Comments about candidate, dropdown values of edit pipeline reviewers)
  --app-label-primary-font-color: #24859a; 

  //Links
  --app-link-font-color: #2da6c0;  
  --app-active-link-font-color: #4f4f4f; 

  //Buttons
  --app-button-primary-font-color: #2da6c0; 
  --app-button-secondary-font-color: #24859a; 
  --app-button-danger-font-color: #eb5757; 

  //Badges
  --app-badges-primary-font-color: #ffffff; 
  --app-badges-secondary-font-color: #27ae60; 
  --app-badges-tertiary-font-color: #eb5757; 
  --app-badge-pending-font-color:#D2A20E; 

  //Breadcrumb
  --app-breadcrumb-primary-font-color: #2da6c0; 
  --app-breadcrumb-secondary-font-color: #4f4f4f; 

  //Cards
  --app-cards-primary-font-color: #333333; 
  --app-cards-secondary-font-color: #828282;  
  --app-cards-tertiary-font-color: #4f4f4f; 

  //Buttons
  --app-buttons-primary-font-color: #ffffff; 
  --app-buttons-secondary-font-color: #2da6c0; 
  --app-buttons-tertiary-font-color: #24859a; 
  --app-buttons-quaternary-font-color: #eb5757; 
  --app-buttons-quaternary-hover-font-color:#e24848; 

  //Input Fields
  --app-input-fields-placeholder-font-color: #bdbdbd; 
  --app-input-fields-default-font-color: #333333; 
  --app-input-fields-primary-font-color:#000000; 

  //sidebar
  --app-sidebar-primary-font-color: #4f4f4f; 
  --app-sidebar-seconday-font-color: #333333; 
  --app-sidebar-line-Btn-default-font-color:#BDBDBD; 
  --app-sidebar-line-button-active-font-color:#FFFFFF; 

  //========================Border Colors=======================

  //Input Fields
  --app-input-field-primary-border-color: #24859a; 
  --app-input-field-secondary-border-color: #bdbdbd; 
  --app-input-field-tertiary-border-color: #e0e0e0; 

  //View details box
  --app-view-details-box-primary-border-color: #2da6c0; 

  //Cards
  --app-cards-primary-border-color: #24859a;
  --app-cards-secondary-border-color: #e0e0e0;
  --app-cards-tertiary-border-color: #ffffff;
  --app-cards-content-border-bottom-color: #bdbdbd;

  //Table
  --app-table-primary-border-color: #bdbdbd; 
  --app-table-secondary-border-color: #e0e0e0; 

  //Buttons
  --app-buttons-primary-border-color: #2da6c0; 
  --app-buttons-secondary-border-color: #ffffff; 
  --app-buttons-tertiary-border-color: #eb5757; 
  --app-buttons-tertiary-border-hover-color: #e24848; 
  --app-buttons-quaternary-border-color: #24859a; 

  //Badges
  --app-badges-primary-border-color: #2da6c0; 

  //Modal
  --app-modal-step-default-border-color: #bdbdbd; 

  //Dropdown
  --app-dropdown-highlight-color: #24859a; 

  //Sidebar
  --app-sidebar-button-row-box-border-color:#828282; 
  --app-sidebar-switchButtons-box-border-color:#24859A; 

  //Alert
  --app-error-alert-border-color:#FFFFFF; 

  //========================Buttons Colors=======================

  --app-buttons-default-body-color: #2da6c0; 
  --app-buttons-danger-body-color: #eb5757; 
  --app-buttons-danger-hover-body-color: #e24848; 
  --app-buttons-success-body-color: #27ae60; 
  --app-buttons-success-hover-body-color:#229b55; 
  --app-buttons-light-body-color: #ffffff;  
  --app-buttons-dark-body-color: #24859a; 

  //========================Badges Colors=======================

  --app-badge-success-body-color: #27ae60; 
  --app-badge-danger-body-color: #eb5757; 
  --app-badge-warning-body-color: #f2c94c;
}
