.modalBodyWrapper{
  padding: 0 24px 0 24px;
}



.modalHeaderTopic {
  color: var(--app-gray-900-color);
  font-size: var(--app-x-extra-large-font-size);
  font-style: normal;
  font-weight: var(--app-bold-font-weight);
  line-height: 28px; 
  margin-top: 16px;
  margin-bottom: 10px;
  margin-left: 1rem;
}

.modalHeaderSubTopic{
  color: var(--app-gray-600-color);
  font-size: var(--app-primary-font-size);
  font-style: normal;
  font-weight: var(--app-regular-font-weight);
  line-height: 20px;
}
.waitingLoaderWrapper{
  display: flex;
  align-items: center;
  flex-direction: row;
}

.loader {
  border: 4px solid var(--app-gray-100-color);
  border-radius: 50%;
  border-top: 4px solid var(--app-primary-600-color);
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}