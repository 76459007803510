@import "./styles/fonts.scss";
@import "./styles/colors.scss";
@import "~bootstrap/scss/bootstrap";
@import "@trendmicro/react-sidenav/dist/react-sidenav.css";

body {
  margin: 0;
  font-family: var(--app-primary-font-style);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.sb-avatar__text{
  border: 2px solid var(--app-base-white-color);
}


// delete code after this

// body {
//   margin: 0;
//   font-family: var(--app-primary-font-style);
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//     monospace;
// }

// .nav-link:hover {
//   border-color: transparent !important;
// }

.nav-link.active {
  // color: #2b4644 !important;
  color: var(--app-primary-700-color)!important;
  // background-color: #90c5fd !important;
  border: none ;
  border-bottom: 3px solid var(--app-primary-600-color) !important;
  border-radius: 3px;
}

.tab-content>.active {
  color: #474b4fc2 !important;
  // background-color: #90c5fd !important;
  font-size: 15px;
  margin-top: -8px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.react-pdf__Page__canvas {
  top: 0 !important;
  left: 0 !important;
  transform: translate(0%, 0%) !important;
}

.react-pdf__Page__textContent {
  top: 0 !important;
  left: 0 !important;
  transform: translate(0%, 0%) !important;
}

.sidenav---sidenav-toggle---1KRjR {
  width: 50px !important;
  height: 50px !important;
  top: 25px !important;
  left: 8px !important;
}

//for control invalid 
.form-control.is-invalid {
  background-image: none !important;
}




// ============================================== New UI Override CSS ===============================================================================


.sidenav---highlighted---oUx9u {
  // background: var(--app-primary-700-color-three) !important;
  background: var(--app-secondary-background-color) !important;
  opacity: 1 !important;
}

//side nav styles
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T {
  background: var(--app-base-white-color);
  line-height: 30px;
  height: unset;
  padding: 4px;

  &:hover{
    background: var(--app-secondary-background-color);
    border-radius: 10px;
  }
}

.sidenav---sidenav---_2tBP> .sidenav---expanded---1KdUL> .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- .sidenav---navicon---3gCRo + .sidenav---navtext---1AE_f{
 width: 260px;
//  height: 90vh;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T{
  padding: 0 15px 0 15px;
}
//side nav styles default text color
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- .sidenav---navicon---3gCRo + .sidenav---navtext---1AE_f{
  color: var(--app-base-black-color);
}
//side nav styles text color active
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u .sidenav---navicon---3gCRo + .sidenav---navtext---1AE_f {
  color: var(--app-primary-900-color) !important;
}
//side nav styles text color hover
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- .sidenav---navicon---3gCRo + .sidenav---navtext---1AE_f:hover{
  color: var(--app-primary-900-color) !important;
}
//side nav styles icon
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
  line-height: 24px;
  height: unset;
  width: 54px;
}
//side nav background color change start ===========
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T::after {
  opacity: 1 !important;
}
//side nav styles expanded
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61{
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 2px;
}
//side nav styles active stroke
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > *, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > * {
  stroke: var(--app-primary-900-color);
}
//side nav styles default stroke
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > *{
  stroke: var(--app-gray-500-color);
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T::before {
  content: " ";
  width: 2px;
  height: 18px;
  left: 10px;
  top: 5px;
  position: absolute;
  border-left: 2px var(--app-primary-900-color) solid;
}
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL>.sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL>.sidenav---navitem---9uL5T::after {
  background:  var(--app-base-white-color);
  opacity: 1 !important;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---selected---1EK3y.sidenav---expanded---1KdUL>.sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u.sidenav---selected---1EK3y.sidenav---expanded---1KdUL>.sidenav---navitem---9uL5T::after {
  opacity: 1 !important;
  background:  var(--app-base-white-color);
}

//side nav bar adding border left when selected start ============
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T::before,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T::before {
  width: 3px !important;
  height: 50px !important;
  left: 1px !important;
  top: 0px !important;
  position: absolute !important;
  border-left: 3px  var(--app-base-white-color) solid !important;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL>.sidenav---navitem---9uL5T::before,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL>.sidenav---navitem---9uL5T::before {
  width: 3px !important;
  height: 50px !important;
  left: 1px !important;
  top: 0px !important;
  position: absolute !important;
  border-left: 3px  var(--app-base-white-color) solid !important;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---selected---1EK3y.sidenav---expanded---1KdUL>.sidenav---navitem---9uL5T::before,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u.sidenav---selected---1EK3y.sidenav---expanded---1KdUL>.sidenav---navitem---9uL5T::before {
  width: 3px !important;
  height: 50px !important;
  left: 1px !important;
  top: 0px !important;
  position: absolute !important;
  border-left: 3px  var(--app-base-white-color) solid !important;
}

//side nav bar adding border left when selected end ============

//Make text and svg icon bold when selected start =============
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T {
  font-weight: var(--app-bold-font-weight) !important;

  svg {
    stroke-width: 2px !important;
  }
}

//Make text and svg icon bold when selected end =============

//Background color changes in small side nav start ==========
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL{
  width: 265px;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T{
  background: var(--app-secondary-background-color);
  border-radius: 10px;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ .sidenav---navitem---9uL5T{
  border-radius: 10px;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-{
  border-radius: 10px;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after {
background:  var(--app-base-white-color);
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61{
  padding-left: 30px;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y>.sidenav---navitem---9uL5T {
  // background: var(--app-primary-600-color) !important;
  opacity: 1 !important;
}

.sidenav---sidenav---_2tBP .sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y>.sidenav---navitem---9uL5T {
  opacity: 1 !important;
}

//Background color changes in small side nav end ==========

//breadcrumb :: before icon override start =========
.breadcrumb-item+.breadcrumb-item::before {
  content: ">";
  font-size: 20px;
  line-height: 20px;
}

//breadcrumb :: before icon override end =========

//Input boxes placeholder color overide start ======
::placeholder {
  color: var(--app-gray-300-color) !important;
  font-family: var(--app-primary-font-style) !important;
  font-size: var(--app-semi-small-font-size) !important;
  font-weight: var(--app-regular-font-weight) !important;
}

:-ms-input-placeholder {
  color: var(--app-gray-300-color) !important;
  font-family: var(--app-primary-font-style) !important;
  font-size: var(--app-semi-small-font-size) !important;
  font-weight: var(--app-regular-font-weight) !important;
}

::-ms-input-placeholder {
  color: var(--app-gray-300-color) !important;
  font-family: var(--app-primary-font-style) !important;
  font-size: var(--app-semi-small-font-size) !important;
  font-weight: var(--app-regular-font-weight) !important;
}

//Input boxes placeholder color overide end ======

//Material table border remove, change padding start ===========

.MuiTableCell-root {
  border: none !important;
  padding: 16px 10px !important;
}

//Material table border remove, change padding end ===========

//Material table hover color change start ==========
.MuiTableRow-root {
  cursor: pointer;

  &:hover {
    background-color: rgba(45, 166, 192, 0.1) !important;
  }
}

.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: rgba(45, 166, 192, 0.1) !important;
}

//Material table hover color change end ==========

//Bootstrap modal border radius remove override css start ==========

.modal-content {
  border-radius: 12px !important;
}

//Bootstrap modal border radius remove override css end ==========

//Google chart Tool tip blinking error resolve start ===========================

svg>g>g:last-child {
  pointer-events: none;
}

//Google chart Tool tip blinking error resolve end ===========================

//overide button click z-index amount start========================

.dropdown-menu {
  z-index: 40;
}

//overide button click z-index amount end========================

//Bootstrap medium size modal width overriding start ===========

@media (min-width: 992px) {
  .modal-xl {
    max-width: 1140px !important;
  }

  .modal-lg {
    max-width: 800px !important;
  }

  .modal-md {
    max-width: 700px !important;
  }

  .modal-sm {
    max-width: 300px !important;
  }
}

//Bootstrap medium size modal width overriding end ===========

//Analytics month range calender modal position adjustment start ==================================

.month-picker>.rmp-container .rmp-popup.light {
  transform: translate(-441px, 70px) !important;
}

@media screen and (max-width: 767px) {
  .month-picker>.rmp-container .rmp-popup.light {
    transform: translate(-9px, 424px) !important;
  }
}

//Analytics month range calender modal position adjustment end ==================================

//dropdown toggle button background make white start ======================================
.dropdown-toggle.btn.btn-primary {
  background:  var(--app-base-white-color) !important;
}

//dropdown toggle button background make white end ======================================

//pagination  button active background and colour======================================
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: var(--app-primary-600-color);;
  border-color: #007bff;
}
//pagination  button active height and width======================================
.page-link {
  position: relative;
  display: block;
  padding: 0.4rem 0.5rem;
  margin-left: -1px;
  line-height: 0.8;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

//interview portal icons 
.eWhPFZ {
  width: fit-content !important;
}
.hffvKg {
  width: fit-content !important;
}

//interview portal mobile icons 
@media only screen and (max-width: 600px) {
  .eWhPFZ {
    width: fit-content !important;
    margin: 0px !important;
  }
}
@media only screen and (max-width: 600px) {
  .jWWUwA{
    margin: 0px !important;
  }
}

//accordion active and n
.accordion-button:not(.collapsed) {
  background-color: transparent;
  border-radius: 5px; 
  margin: 0%;
}
.accordion-button {
  border: none;
  background-color: transparent;
  border-radius: 5px; 
}

.accordion-button:focus {
  border: none;
  border-color: white;
  background-color: transparent;
  border-radius: 5px; 
}

.accordion-button:focus {
  box-shadow: none
}
.accordion-button:not(.collapsed) {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.accordion-button.collapsed{
  background-color: none;
  padding-right: 2%;
  border-radius: 4px;
}

.accordion-item{
  border: none;
  border-radius: 5px;
  margin: 0%;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.accordion-body{
  background-color: var(--app-primary-50-color);
}

.videoTileBtn {
  margin-left: 15px;
  width: fit-content !important;
}

.endMeetingBtn {
  width: fit-content !important;
  background-color: var(--app-error-600-color) !important;
  border: none !important;
  color: var(--app-base-white-color) !important;
  box-shadow: none !important;

  &:hover {
    background-color: var(--app-error-500-color) !important;
  }
}
.form-check-input:checked{
  background-color: var(--app-primary-600-color);
  border: 1px solid var(--app-primary-200-color);
  width: 1rem;
  height: 1rem;
}
.accordion-button:not(.collapsed)::after{
  margin: 0 !important;
  position: absolute;
  right: 1rem !important;
}
.accordion-button::after{
  margin: 0 !important;
  position: absolute;
  right: 1rem !important;
}
.accordion-button{
  padding: 8px !important;
}
.form-check-input:focus {
  box-shadow: none !important;
}
.accordion-item:first-of-type{
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}