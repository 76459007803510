    $color-Secoundary: var(--app-primary-700-color);

    // selected colors by user
    $selected-primary-color: var(--selected-primary-color);

    //Default Label Size
   .defaultCheckBox {
        font-size: var(--app-primary-font-size);
        font-family: var(--app-primary-font-style);
        color: var(--app-base-black-color);
    }

    .defaultCheckBoxLight{
        font-size: var(--app-primary-font-size);
        font-family: var(--app-primary-font-style);
        color: #828282;
    }

    .checkBoxLinkText{
        font-family: var(--app-primary-font-style);
        color: var(--app-base-black-color);
        text-decoration: underline;

        &:hover{
            color: var(--app-base-black-color);
        }
    }

    .checkBoxLinkTextInvalid{
        font-family: var(--app-primary-font-style);
        color: #dc3545;
        text-decoration: underline;

        &:hover{
            color: #dc3545;
        }
    }

    
    .defaultCheckBox {
        width: 100%;
        margin: 0px auto;
        position: relative;
        display: block;
        padding-left: 0;
        label {
            position: relative;
            min-height: 34px;
            display: block;
            padding-left: 40px;
            padding-top: 5px;
            margin-bottom: 0;
            font-weight: normal;
            cursor: pointer;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                margin: 4px 4px 4px 1px;
                width: 21px;
                height: 21px;
                transition: transform 0.28s ease;
                border-radius: 8px;
                border: 1px solid $selected-primary-color !important;
            }
            &:after {
                content: '';
                display: block;
                width: 10px;
                height: 5px;
                border-bottom: 1px solid #fff;
                border-left: 1px solid #fff;
                transform: rotate(-45deg) scale(0);
                transition: transform ease 0.25s;
                position: absolute;
                top: 11px;
                left: 7px;
            }
        }
        input[type='checkbox'] {
            width: auto;
            opacity: 0.00000001;
            position: absolute;
            left: 0;
            margin-left: -20px;
            &:checked~label {
                &:before {
                    border: 1px solid $selected-primary-color !important;
                    background-color: $selected-primary-color !important;
                }
                &:after {
                    transform: rotate(-45deg) scale(1);
                }
            }
            &:focus+label::before {
                outline: 0;
            }
        }
    }

    .defaultCheckBoxLight {
        width: 100%;
        margin: 0px auto;
        position: relative;
        display: block;
        padding-left: 0;
        label {
            position: relative;
            min-height: 34px;
            display: block;
            padding-left: 40px;
            padding-top: 5px;
            margin-bottom: 0;
            font-weight: normal;
            cursor: pointer;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                margin: 4px 4px 4px 1px;
                width: 21px;
                height: 21px;
                transition: transform 0.28s ease;
                border-radius: 8px;
                border: 1px solid #828282
            }
            &:after {
                content: '';
                display: block;
                width: 10px;
                height: 5px;
                border-bottom: 1px solid #fff;
                border-left: 1px solid #fff;
                transform: rotate(-45deg) scale(0);
                transition: transform ease 0.25s;
                position: absolute;
                top: 11px;
                left: 7px;
            }
        }
        input[type='checkbox'] {
            width: auto;
            opacity: 0.00000001;
            position: absolute;
            left: 0;
            margin-left: -20px;
            &:checked~label {
                &:before {
                    border: 1px solid $selected-primary-color !important;
                    background-color: $selected-primary-color !important;
                }
                &:after {
                    transform: rotate(-45deg) scale(1);
                }
            }
            &:focus+label::before {
                outline: 0;
            }
        }
    }

    .formCheckLabel{
        font-family: var(--app-primary-font-style);
        font-size: var(--app-semi-small-font-size);
        font-weight: var(--app-semi-bold-font-weight);
        color: var(--app-base-black-color);
    }
    .formCheckLabelInvalid{
        font-family: var(--app-primary-font-style);
        font-size: var(--app-semi-small-font-size);
        font-weight: var(--app-semi-bold-font-weight);
        color: var(--app-error-700-color);
    }

 

 