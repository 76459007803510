.modalBodyWrapper{
  padding: 24px;
}

.modalCancelBtnDiv {
  float: right;

  .modalCancelBtn {
    border-radius: 6px;
    background: var(--app-base-white-color) !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0.375rem 0.375rem;
    line-height: 1;
    stroke: var(--app-gray-500-color);
  
    &:hover {
      stroke: var(--app-gray-600-color);
    }
  
    &:focus {
      stroke: var(--app-gray-600-color);
    }
  
    &:active {
      stroke: var(--app-gray-600-color);
    }
  }
}

.modalHeaderDiv {
  text-align: center;
  margin-top: 5%;
}

.popUpBoxTopicDiv {
  text-align: left;
  margin-top: 5%;
}

.popUpBoxTopic{
  color: var(--app-gray-900-color);
  font-size: var(--app-x-extra-large-font-size);
  font-style: normal;
  font-weight: var(--app-bold-font-weight);
  line-height: 28px; 
  margin-top: 16px;
  margin-bottom: 0;
}

.popUpBoxMessage{
  color: var(--app-gray-600-color);
  font-size: var(--app-primary-font-size);
  font-style: normal;
  font-weight: var(--app-regular-font-weight);
  line-height: 20px;
}

.documentLink {
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  text-align: center;
  color: var( --app-base-black-color);
}

.actionBtnRow{
    margin-top: 1.4rem;
}
