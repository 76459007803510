.commonErrorPage {
  min-height: 100vh;
  padding-top: 3%;
}

.companyLogo {
  margin-bottom: 3%;
}

.bodyRow {
  margin-top: 5%;
}

.warningLabel {
  text-align: left;
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-xxx-extra-large-font-size);
  margin-top: 3%;
  text-transform: uppercase;
}

.errorType {
  text-align: left;
  font-family: var(--app-primary-font-style);
  font-size: var(--app-xx-extra-large-font-size);
  font-weight: var(--app-bold-font-weight);
  color: var(--app-error-700-color);
  margin-top: 2%;
  margin-bottom: 4%;
}


@media only screen and (max-width: 600px) {
  .companyLogo {
    margin-bottom: 10%;
  }
}
