.modalCancelBtnDiv {
  float: right;
}

.modalCancelBtn {
  border-radius: 6px;
  background: rgba(45, 166, 192, 0.1) !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0.375rem 0.375rem;
  line-height: 1;

  &:hover {
    background: rgba(45, 166, 192, 0.3) !important;
  }

  &:focus {
    background: rgba(45, 166, 192, 0.3) !important;
  }

  &:active {
    background: rgba(45, 166, 192, 0.3) !important;
  }
}

.modalHeaderDiv {
  text-align: center;
  margin-top: 5%;
}

.popUpBoxTopicDiv {
  text-align: center;
  margin-top: 5%;
}

.popUpBoxTopic {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-xx-extra-large-second-font-size);
  color: var(--app-gray-600-color);
}

.popUpBoxMessage {
  font-family: var(--app-primary-font-style);
  font-weight: var(--app-bold-font-weight);
  font-size: var(--app-primary-font-size);
  color: var(--app-base-black-color);
  text-align: center;
}

.actionBtnRow {
  margin-top: 6%;
  margin-bottom: 5%;
}

.documentLink {
  font-family: var(--app-primary-font-style);
  font-size: var(--app-primary-font-size);
  text-align: center;
  color: var(--app-base-black-color);
}